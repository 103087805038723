<template>
<div v-if="images.length >= minNumToShow" :class="`c-image-scroller ${(controlsLiveRegion) ? 'c-image-scroller--controls-live-region' : ''}`" ref="thumbnailWrapper">
    <div class="c-image-scroller__image-row">
        <div v-if="scrollStep > 0" class="c-image-scroller__thumbnail-nav c-image-scroller__thumbnail-nav--left">
            <button type="button" class="c-button c-button--round c-hero__button" @click="scrollLeft">
				<span aria-hidden="true" class="c-icon c-icon--s c-icon--flip-x"><svg><use xlink:href="#icon-arrow"></use></svg></span>
                <span class="t-visually-hidden">{{scrollLeftText}}</span>
            </button>
		</div>
        <div class="c-image-scroller__thumbnails" ref="thumbnails" :style="`left: -${thumbnailWidth*scrollStep*stepLength}%`">
            <template v-for="(image, index) in images">
                <component
					:is="(controlsLiveRegion) ? 'button' : 'a'"
					:type="(controlsLiveRegion) ? 'button' : undefined"
					:aria-pressed="controlsLiveRegion ? ((currentIndex === index) ? 'true' : 'false') : undefined"
					:class="`c-image-scroller__thumbnail-button ${(controlsLiveRegion && currentIndex === index) ? 'c-image-scroller__thumbnail-button--current' : ''}`" :id="image.id"
					:href="(isLinks) ? image.href : undefined"
					@click="(controlsLiveRegion) ? selectImage(index, image.id) : undefined"
					:tabindex="(imageInView(index) ? '0' : '-1')">
                    <img class="c-image-scroller__thumbnail" :src="image.url" :alt="image.text" />
                </component>
            </template>
        </div>
        <div v-if="showNavRight" class="c-image-scroller__thumbnail-nav c-image-scroller__thumbnail-nav--right">
            <button type="button" class="c-button c-button--round c-hero__button" @click="scrollRight" ref="buttonScrollRight">
				<span aria-hidden="true" class="c-icon c-icon--s"><svg><use xlink:href="#icon-arrow"></use></svg></span>
                <span class="t-visually-hidden">{{scrollRightText}}</span>
            </button>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'VImageScroller',
    props: {
        controlsLiveRegion: {
            type: Boolean,
            default: false
        },
		isLinks: {
			type: Boolean,
			default: false
		},
        images: {
            type: Array,
            default: () => []
        },
        thumbnailWidth: {
            type: Number,
            default: 22
        },
        stepLength: {
            type: Number,
            default: 1
        },
		scrollLeftText: {
			type: String,
			default: 'Scrolla vänster'
		},
		scrollRightText: {
			type: String,
			default: 'Scrolla höger'
		},
		minNumToShow: {
			type: Number,
			default: 1
		}
    },
    data() {
        return {
            currentIndex: 0,
            scrollStep: 0,
            showNavRight: this.images.length > (100 / this.thumbnailWidth),
        }
    },
    computed: {
        firstInView() {
            return this.scrollStep;
        },
        lastInView() {
            return Math.floor(this.scrollStep * this.stepLength + (100 / this.thumbnailWidth));
        }

    },
    methods: {
        scrollLeft() {
            this.scrollStep -= 1;
			this.currentIndex -= 1;
            this.$emit('selectImage', this.currentIndex, this.images[this.currentIndex].id);
            this.checkIfShowNavRight();
        },
        scrollRight() {
			this.scrollStep += 1;
			this.currentIndex += 1;
            this.$emit('selectImage', this.currentIndex, this.images[this.currentIndex].id);
            this.checkIfShowNavRight();
        },
        selectImage(index, id) {
            this.currentIndex = index;
            this.$emit('selectImage', index, id);
        },
        checkIfShowNavRight() {
			if ((this.currentIndex + 1) === this.images.length) {
                this.showNavRight = false;
			} else {
                this.showNavRight = true;
			}
		},
        imageInView(buttonIndex) {
            return (buttonIndex >= this.firstInView && buttonIndex < this.lastInView);
        },
    },
    mounted: function () {}
}
</script>
