<template>
	<div class="c-object">
		<a :href="url"><span class="t-visually-hidden">{{title}}</span></a>
		<div class="c-object__media" v-if="url && image">
			<img v-lazy="image.imgSrc" :key="image.imgSrc" :alt="image.altText" class="c-object__image">
		</div>
		<div class="c-object__content">
			<div class="c-object__head">
				<h2 class="c-object__title">{{address}}</h2>
				<span class="c-object__byline">{{fixedSize}} i {{city}}</span>
			</div>
			<div v-if="overflowFeaturesCount > 1" class="c-object__icons">
				<span v-for="(feature, index) in features" :key="index" class="c-object__icon-wrapper">
					<span aria-hidden="true" class="c-icon c-icon--xs c-object__icon">
						<svg>
							<use :xlink:href="`#icon-${feature.icon}`"></use>
						</svg>
					</span>
				</span>
				<span class="c-object__icon-wrapper">
					<span class="c-object__icons-more">+{{overflowFeaturesCount}}</span>
				</span>
			</div>
			<div v-else class="c-object__icons">
				<span v-for="(feature, index) in features" :key="index" class="c-object__icon-wrapper">
					<span aria-hidden="true" class="c-icon c-icon--xs c-object__icon">
						<svg>
							<use :xlink:href="`#icon-${feature.icon}`"></use>
						</svg>
					</span>
				</span>
			</div>
			<div class="c-object__body">
				<p class="c-object__text">{{title}}</p>
			</div>
		</div>
	</div>
</template>

<script>

import VueLazyLoad from 'vue-lazyload';

export default {
	name: 'VObject',
	components: {
		VueLazyLoad
	},
	props: {
		id: {
			type: String
		},
		url: {
			type: String
		},
		image: {
			type: String
		},
		title: {
			type: String
		},
		sizeFrom: {
			type: Number
		},
		sizeTo: {
			type: Number
		},
		premiseType: {
			type: Array,
			default: () => []
		},
		address: {
			type: String
		},
		features: {
			type: Array,
			default: () => []
		},
		overflowFeaturesCount: {
			type: Number
		},
		city: {
			type: String
		},
		district: {
			type: String
		}
	},
	computed: {
		fixedSize () {
			if (this.sizeFrom === this.sizeTo) {
				return `${this.sizeFrom} kvm`;
			} else {
				return `${this.sizeFrom} - ${this.sizeTo} kvm`
			}
		},
		randomUrl () {
			return Math.random();
		}
	}
}
</script>
