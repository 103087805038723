/**
 * Form block validation and ajax
 */

export default function () {

	if (!document.querySelector('.c-form-block')) {
		return;
	}

	// Regex pattern to test valid email
	const mailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

	// Regex pattern to test valid phonenumer
	const phonePattern = /^(([+]46)\s*(7)|07)[02369]\s*(\d{4})\s*(\d{3})$/ //eslint-disable-line

	const formBlock = document.querySelectorAll('.c-form-block');

	formBlock.forEach((block) => {
		const form = block.querySelector('form');
		const submit = block.querySelector('button');
		const inputs = block.querySelectorAll('input:not([type=hidden])');
		const postSuccessMsg = block.querySelector('.c-form-block__success');
		const postErrorMsg = block.querySelector('.c-form-block__error');
		const recaptcha = block.querySelector('.g-recaptcha');

		const appendError = (input, msg) => {
			let error = document.createElement('span');
			error.id = `${input.id}-error`;
			error.classList.add('c-form-block__error');
			error.textContent = msg;

			input.classList.add('has-error');
			input.setAttribute('aria-describedby', `${input.id}-error`);

			input.parentNode.appendChild(error);
		};

		const clearErrors = (input) => {
			if (input.classList.contains('has-error')) {
				let error = input.parentNode.querySelectorAll('.c-form-block__error');
				error.forEach((error) => { error.remove(); });

				input.setAttribute('aria-describedby', '');
				input.classList.remove('has-error');
			}
		};

		submit.addEventListener('click', (e) => {
			e.preventDefault ? e.preventDefault() : (e.returnValue = false);
			postSuccessMsg.style.display = 'none';
			postErrorMsg.style.display = 'none';
			let valid = true;

			// Validate form
			inputs.forEach((input) => {
				clearErrors(input);

				if (input.hasAttribute('data-val-req')) {
					if (!input.value) {
						appendError(input, input.getAttribute('data-val-req'));
						valid = false;
					}
				}
				if (input.hasAttribute('data-val-email')) {
					if (!mailPattern.test(input.value)) {
						appendError(input, input.getAttribute('data-val-email'));
						valid = false;
					}
				}
				if (input.hasAttribute('data-val-phone')) {
					if (!phonePattern.test(input.value)) {
						appendError(input, input.getAttribute('data-val-phone'));
						valid = false;
					}
				}
				if (input.hasAttribute('data-val-emailorphone')) {
					if (!mailPattern.test(input.value) && !phonePattern.test(input.value)) {
						appendError(input, input.getAttribute('data-val-emailorphone'));
						valid = false;
					}
				}
			});

			// Validate recaptcha
			if (recaptcha) {
				clearErrors(recaptcha);
				if (!recaptchaChecked) {//eslint-disable-line
					appendError(recaptcha, recaptcha.getAttribute('data-val-recaptcha'));
					valid = false;
				}
			}

			// Send if valid
			if (valid) {
				let formData = new FormData(form);
				let url = form.getAttribute('action');

				fetch(url, {
					method: 'POST',
					body: formData
				})

					.then((res) => {
						if (res.ok) {
							return res.text()
								.then(() => {
									form.remove();
									postSuccessMsg.style.display = 'block';
								});
						}
						else {
							return res.text()
								.then((err) => { //eslint-disable-line
									throw new Error();
								});
						}
					})
					.catch((error) => { //eslint-disable-line
						postErrorMsg.style.display = 'block';
					});
			}
		});

		// Clear errors on new input
		inputs.forEach((input) => {
			input.addEventListener('input', () => {
				clearErrors(input);
			});
		});
	});
}
