/**
 * Media gallery
 */

export default function() {

	if (!document.querySelector('[data-mg="init"]')) {
		return;
	}

	document.querySelectorAll('[data-mg="init"]').forEach(item => {
		let toggle = item.querySelector('[data-mg="toggle"]');
		let gallery = item.querySelector('[data-mg="gallery"]');
		let bpToggle = item.querySelector('[data-mg="bp-toggle"]');
		let blueprint = item.querySelector('[data-mg="blueprint"]');

		toggle.addEventListener('click', () => {
			if (bpToggle && blueprint) {
				blueprint.style.display = 'none';
			}
			gallery.style.display = 'block';
			gallery.scrollIntoView();
		});

		if (bpToggle && blueprint) {
			bpToggle.addEventListener('click', () => {
				gallery.style.display = 'none';
				blueprint.style.display = 'block';
				blueprint.scrollIntoView();
			});
		}
	});
}
