var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-filter", {
        attrs: {
          objectsFetched: _vm.objectsFetched,
          objects: _vm.filteredObjects,
          multiSelectFilters: _vm.multiSelectFilters,
        },
        on: {
          setMultiSelectFilter: _vm.handleSelectedFilters,
          setSelectedPlacesLength: _vm.handleSelectedPlaces,
          setSelectedTypesOfBuildingsLength: _vm.handleSelectedTypesOfBuildings,
          setMinSize: _vm.handleMinSize,
          setMaxSize: _vm.handleMaxSize,
          setSortingOption: _vm.handleSortingOption,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "c-object-listing", staticStyle: { display: "block" } },
        [
          _c(
            "div",
            { staticClass: "c-object-listing__inner o-width-limiter--full" },
            _vm._l(_vm.filteredObjects, function (object, index) {
              return _c(
                "div",
                { key: index, staticClass: "c-object-listing__item" },
                [
                  _c("v-object", {
                    attrs: {
                      url: object.url,
                      image: object.image,
                      title: object.title,
                      sizeFrom: object.sizeFrom,
                      sizeTo: object.sizeTo,
                      adjustedMax: object.adjustedMax,
                      city: object.city,
                      district: object.district,
                      id: `${object.district.toLowerCase()}-${object.city.toLowerCase()}`,
                      address: object.address,
                      premiseType: object.premiseType,
                      features: object.selectedFeatures,
                      overflowFeaturesCount: object.overflowFeaturesCount,
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.isLoading
            ? _c("div", { staticClass: "c-object-listing__loading" }, [
                _vm._m(0),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-loading" }, [
      _c("span", { staticClass: "t-visually-hidden" }, [_vm._v("Laddar...")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }