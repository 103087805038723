<template>
	<div class="c-popup" :class="isOpen ? 'c-popup--overlay' : ''">
		<div v-click-outside="close">
			<button
				@click="open"
				class="c-button c-popup__toggle"
				:aria-expanded="isOpen ? 'true' : 'false'"
				aria-controls="popup-content"
				@keydown.esc="close"
			>
				<span>{{ name }}</span>
			</button>
			<transition name="toggle">
				<div
					id="popup-content"
					class="c-popup__content"
					v-show="isOpen"
					ref="content"
				>
					<button class="c-popup__close" @click="close">
						<span aria-hidden="true" class="c-icon c-icon--xs">
							<svg><use xlink:href="#icon-close"></use></svg>
						</span>
						<span class="t-visually-hidden"
							>Stäng {{ name.toLowerCase() }}</span
						>
					</button>
					<div class="c-popup__inner">
						<slot />
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import vClickOutside from "v-click-outside";
import {
	disableBodyScroll,
	enableBodyScroll,
	clearAllBodyScrollLocks
} from "body-scroll-lock-upgrade";

export default {
	name: "VPopup",
	directives: {
		clickOutside: vClickOutside.directive
	},
	props: {
		name: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			isOpen: false
		};
	},
	beforeDestroy() {
		clearAllBodyScrollLocks();
	},
	methods: {
		open() {
			disableBodyScroll(this.$refs.content);
			this.isOpen = true;

			this.focusFirst();
		},
		close(e) {
			// If the click was on hero button, don't close
			if (e.target.hasAttribute("data-open-popup")) {
				return;
			}
			enableBodyScroll(this.$refs.content);
			this.isOpen = this.isOpen && false;
		},
		focusFirst() {
			const firstFocusable = this.$refs.content.querySelector(
				"a, button, input, textarea, select, details, [tabindex]:not([tabindex='-1'])"
			);
			if (firstFocusable) {
				firstFocusable.focus();
			}
		}
	}
};
</script>
