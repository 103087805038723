<template>
<div class="c-gallery">
    <figure class="c-gallery__main-image" id="gallery" :aria-live="isLive !== '' ? isLive : false" :aria-atomic="isAtomic !== '' ? isAtomic : false" :aria-labelledby="currentId">
        <img :src="currentImage.url" :alt="currentImage.text" />
    </figure>
    <v-image-scroller @selectImage="selectImage" :images="images" :step-length="stepLength" aria-controls="gallery" :controls-live-region="true" min-num-to-show="2"></v-image-scroller>
</div>
</template>

<script>
import VImageScroller from '@/components/image-scroller/VImageScroller.vue';
export default {
    name: 'VGallery',
    components: {
        VImageScroller,
    },
    props: {
        images: {
            type: Array,
            default: () => []
        },
        stepLength: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            currentIndex: 0,
            isLive: (this.images.length > 1) ? 'polite' : '',
            isAtomic: (this.images.length > 1) ? 'true' : '',
        }
    },
    computed: {
        currentImage() {
            return this.images[this.currentIndex];
        },
        currentId() {
            if (this.images.length > 1) {
                return (this.images[this.currentIndex]) ? this.images[this.currentIndex].id : this.images[0].id;
            }
        },
    },
    methods: {
        selectImage(index, id) {
            this.currentIndex = index;
			this.currentId = id;
        },
    },
}
</script>
