var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-popup", class: _vm.isOpen ? "c-popup--overlay" : "" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.close,
              expression: "close",
            },
          ],
        },
        [
          _c(
            "button",
            {
              staticClass: "c-button c-popup__toggle",
              attrs: {
                "aria-expanded": _vm.isOpen ? "true" : "false",
                "aria-controls": "popup-content",
              },
              on: {
                click: _vm.open,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  )
                    return null
                  return _vm.close.apply(null, arguments)
                },
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.name))])]
          ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "toggle" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isOpen,
                    expression: "isOpen",
                  },
                ],
                ref: "content",
                staticClass: "c-popup__content",
                attrs: { id: "popup-content" },
              },
              [
                _c(
                  "button",
                  { staticClass: "c-popup__close", on: { click: _vm.close } },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "c-icon c-icon--xs",
                        attrs: { "aria-hidden": "true" },
                      },
                      [
                        _c("svg", [
                          _c("use", { attrs: { "xlink:href": "#icon-close" } }),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "t-visually-hidden" }, [
                      _vm._v("Stäng " + _vm._s(_vm.name.toLowerCase())),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "c-popup__inner" },
                  [_vm._t("default")],
                  2
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }