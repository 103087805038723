/**
 * Mobile menu toggle
 */

export default function() {
	// Hide/show header on scroll
	// Get the header element
	const header = document.getElementById('header');
	
	// Variable to store the last scroll position
	let lastScroll = 0;

	// Event listener for scroll events
	window.addEventListener('scroll', () => {
	// Get the current scroll position
		const currentScroll = window.pageYOffset || document.documentElement.scrollTop;

		if (currentScroll > lastScroll && currentScroll > 95 && window.innerWidth >= 768) {
			// Scrolling down
			header.style.transform = 'translateY(-100%)';
		}
		else {
			// Scrolling up
			header.style.transform = 'translateY(0)';
		}

		// Update the last scroll position
		lastScroll = currentScroll;
	});

	if (!document.querySelector('[data-js="nav"]')) {
		return;
	}

	// Main navigation
	const nav = document.querySelector('[data-js="nav"]');
	const navToggle = document.querySelector('[data-js="nav-toggle"]');
	const text = navToggle.querySelector('[data-js="nav-text"]');
	const utilities = document.querySelectorAll('[data-hide="small"]');
	let navIsOpen = false;

	navToggle.setAttribute('aria-expanded', false);
	navToggle.setAttribute('aria-controls', 'main-navigation');

	navToggle.addEventListener('click', toggleNav);

	function toggleNav() {
		navIsOpen = !navIsOpen;
		navToggle.setAttribute('aria-expanded', navIsOpen);

		navIsOpen ? nav.classList.add('is-open') : nav.classList.remove('is-open');
		navIsOpen ? text.textContent = 'Stäng meny' : text.textContent = 'Öppna meny';

		utilities.forEach(item => {
			if (navIsOpen) {
				item.style.display = 'block';
				document.body.style.overflow = 'hidden';
			}
			else {
				item.style.display = 'none';
				document.body.style.overflow = '';
			}
		});
	}

	if (!document.querySelector('[data-js="drop"]')) {
		return;
	}

	// Utility dropdowns
	const drops = document.querySelectorAll('[data-js="drop"]');

	drops.forEach(drop => {
		let toggle = drop.querySelector('[data-js="drop-toggle"]');
		let close = drop.querySelector('[data-js="drop-close"]');

		toggle.setAttribute('aria-expanded', false);
		toggle.setAttribute('aria-controls', drop.id);

		toggle.addEventListener('click', () => {
			closeDrops();
			if (!drop.classList.contains('is-open')) {
				openDrop(drop);
			}
		});

		close.addEventListener('click', () => {
			closeDrops();
		});
	});

	function openDrop(drop) {
		let toggle = drop.querySelector('[data-js="drop-toggle"]');

		toggle.setAttribute('aria-expanded', true);
		drop.classList.add('is-open');
	}

	function closeDrops() {
		drops.forEach(drop => {
			let toggle = drop.querySelector('[data-js="drop-toggle"]');

			toggle.setAttribute('aria-expanded', false);
			drop.classList.remove('is-open');
		});
	}
}
