var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-filter__sticky-wrapper" }, [
    !_vm.showFilters && !_vm.isDesktop
      ? _c("div", { staticClass: "c-filter__show-filters-banner" }, [
          _c(
            "div",
            { staticClass: "c-mobile-nav__search c-contact-filter__search" },
            [
              _c(
                "label",
                {
                  staticClass: "t-visually-hidden",
                  attrs: { for: "search-input" },
                },
                [_vm._v("Sök")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchValue,
                    expression: "searchValue",
                  },
                ],
                attrs: {
                  type: "text",
                  name: "q",
                  id: "search-input mobile-search-bar input",
                  placeholder: "Sök på namn, roll eller ort...",
                },
                domProps: { value: _vm.searchValue },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.searchContacts.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.searchValue = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm.searchValue
                ? _c(
                    "button",
                    {
                      staticClass: "clear-button",
                      on: {
                        click: function ($event) {
                          _vm.searchValue = ""
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "c-icon c-icon--xxs c-main-nav__close-icon",
                          attrs: { "aria-hidden": "true" },
                        },
                        [
                          _c("svg", [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-close" },
                            }),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "container-search-button" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "c-main-nav__search-button c-contact-filter__button",
                    attrs: { type: "submit" },
                    on: { click: _vm.searchContacts },
                  },
                  [
                    _c("span", { staticClass: "t-visually-hidden" }, [
                      _vm._v("Sök"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "c-icon c-icon--xs c-main-nav__search-icon",
                        attrs: { "aria-hidden": "true" },
                      },
                      [
                        _c("svg", [
                          _c("use", {
                            attrs: { "xlink:href": "#icon-search" },
                          }),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "filter-results-container" }, [
            _c(
              "button",
              {
                staticClass: "filter-button",
                on: { click: _vm.showMobileFilters },
              },
              [
                _c("div", { staticClass: "container-filter-button" }, [
                  _c("span", { staticClass: "filter-text" }, [
                    _vm._v("Filtrera"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "c-icon c-icon--xs slider-icon",
                      attrs: { "aria-hidden": "true" },
                    },
                    [
                      _c("svg", [
                        _c("use", { attrs: { "xlink:href": "#icon-sliders" } }),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showFilters || _vm.isDesktop
      ? _c(
          "div",
          {
            staticClass: "c-filter c-contact-filter",
            class: { "c-filter__mobile": !_vm.isDesktop },
          },
          [
            _vm.isDesktop
              ? _c(
                  "div",
                  {
                    staticClass: "c-filter__wrapper c-contact-filter__wrapper",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "c-filter__content-wrapper c-contact-filter__content-wrapper o-width-limiter",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "c-filter__dropdown-wrapper c-contact-filter__dropdown-wrapper",
                          },
                          [
                            _c(
                              "v-filter-dropdown",
                              {
                                attrs: {
                                  tag: "plats",
                                  "button-text": _vm.selectedCity.name
                                    ? _vm.selectedCity.name
                                    : "",
                                  "dropdown-open":
                                    this.activeFilter === "plats",
                                },
                                on: { "populate-filter": _vm.handleFilterData },
                              },
                              [
                                this.activeFilter === "plats"
                                  ? _c(
                                      "ul",
                                      {
                                        directives: [
                                          {
                                            name: "click-outside",
                                            rawName: "v-click-outside",
                                            value: _vm.handleClickOutside,
                                            expression: "handleClickOutside",
                                          },
                                        ],
                                        staticClass: "c-filter-dropdown__list",
                                      },
                                      [
                                        _vm.activeFilter === "plats"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "c-filter__city-wrapper",
                                              },
                                              _vm._l(
                                                _vm.citiesList,
                                                function (city) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: city.id,
                                                      staticClass:
                                                        "c-filter__city",
                                                      attrs: { value: city.id },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.selectCity(
                                                            city
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(city.name)
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        city.areaReferences,
                                                        function (area) {
                                                          return _c(
                                                            "p",
                                                            {
                                                              key: area.id,
                                                              staticClass:
                                                                "c-filter__city",
                                                              attrs: {
                                                                value: area.id,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectCity(
                                                                      area
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    " +
                                                                  _vm._s(
                                                                    area.name
                                                                  ) +
                                                                  "\n                  "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-filter-dropdown",
                              {
                                attrs: {
                                  tag: "departments",
                                  "button-text": _vm.selectedDepartment.name
                                    ? _vm.selectedDepartment.name
                                    : "",
                                  "dropdown-open":
                                    this.activeFilter === "departments",
                                },
                                on: { "populate-filter": _vm.handleFilterData },
                              },
                              [
                                this.activeFilter === "departments"
                                  ? _c(
                                      "ul",
                                      {
                                        directives: [
                                          {
                                            name: "click-outside",
                                            rawName: "v-click-outside",
                                            value: _vm.handleClickOutside,
                                            expression: "handleClickOutside",
                                          },
                                        ],
                                        staticClass: "c-filter-dropdown__list",
                                      },
                                      [
                                        _vm.activeFilter === "departments"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "c-filter__city-wrapper c-filter-department-wrapper",
                                              },
                                              _vm._l(
                                                _vm.departmentsList,
                                                function (department) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: department.id,
                                                      staticClass:
                                                        "c-filter__city",
                                                      attrs: {
                                                        value: department.id,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.selectDepartment(
                                                            department
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            department.name
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "c-main-nav__search c-contact-filter__search",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "t-visually-hidden",
                                    attrs: { for: "search-input" },
                                  },
                                  [_vm._v("Sök")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.searchValue,
                                      expression: "searchValue",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    name: "q",
                                    id: "search-input",
                                    placeholder:
                                      "Sök på namn, roll eller ort...",
                                  },
                                  domProps: { value: _vm.searchValue },
                                  on: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.searchContacts.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.searchValue = $event.target.value
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _vm.searchValue
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "clear-button",
                                        on: {
                                          click: function ($event) {
                                            _vm.searchValue = ""
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "c-icon c-icon--xxs c-main-nav__close-icon",
                                            attrs: { "aria-hidden": "true" },
                                          },
                                          [
                                            _c("svg", [
                                              _c("use", {
                                                attrs: {
                                                  "xlink:href": "#icon-close",
                                                },
                                              }),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "container-search-button" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "c-main-nav__search-button c-contact-filter__button",
                                        attrs: { type: "submit" },
                                        on: { click: _vm.searchContacts },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "t-visually-hidden" },
                                          [_vm._v("Sök")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "c-icon c-icon--xs c-main-nav__search-icon",
                                            attrs: { "aria-hidden": "true" },
                                          },
                                          [
                                            _c("svg", [
                                              _c("use", {
                                                attrs: {
                                                  "xlink:href": "#icon-search",
                                                },
                                              }),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isDesktop
              ? _c(
                  "div",
                  { staticClass: "c-filter__wrapper c-mobile-filter__wrapper" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "c-filter__content-wrapper c-mobile-filter__content-wrapper o-width-limiter",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "c-filter__dropdown-wrapper c-mobile-filter__dropdown-wrapper",
                          },
                          [
                            _c(
                              "v-filter-dropdown",
                              {
                                attrs: {
                                  tag: "plats",
                                  "button-text": _vm.selectedCity.name
                                    ? _vm.selectedCity.name
                                    : "",
                                  "dropdown-open":
                                    this.activeFilter === "plats",
                                },
                                on: { "populate-filter": _vm.handleFilterData },
                              },
                              [
                                this.activeFilter === "plats"
                                  ? _c(
                                      "ul",
                                      {
                                        directives: [
                                          {
                                            name: "click-outside",
                                            rawName: "v-click-outside",
                                            value: _vm.handleClickOutside,
                                            expression: "handleClickOutside",
                                          },
                                        ],
                                        staticClass: "c-filter-dropdown__list",
                                      },
                                      [
                                        _vm.activeFilter === "plats"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "c-filter__city-wrapper",
                                              },
                                              _vm._l(
                                                _vm.citiesList,
                                                function (city) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: city.id,
                                                      staticClass:
                                                        "c-filter__city",
                                                      attrs: { value: city.id },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.selectCity(
                                                            city
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(city.name)
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-filter-dropdown",
                              {
                                attrs: {
                                  tag: "departments",
                                  "button-text": _vm.selectedDepartment.name
                                    ? _vm.selectedDepartment.name
                                    : "",
                                  "dropdown-open":
                                    this.activeFilter === "departments",
                                },
                                on: { "populate-filter": _vm.handleFilterData },
                              },
                              [
                                this.activeFilter === "departments"
                                  ? _c(
                                      "ul",
                                      {
                                        directives: [
                                          {
                                            name: "click-outside",
                                            rawName: "v-click-outside",
                                            value: _vm.handleClickOutside,
                                            expression: "handleClickOutside",
                                          },
                                        ],
                                        staticClass: "c-filter-dropdown__list",
                                      },
                                      [
                                        _vm.activeFilter === "departments"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "c-filter__city-wrapper c-filter-department-wrapper",
                                              },
                                              _vm._l(
                                                _vm.departmentsList,
                                                function (department) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: department.id,
                                                      staticClass:
                                                        "c-filter__city",
                                                      attrs: {
                                                        value: department.id,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.selectDepartment(
                                                            department
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            department.name
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "c-filter__choices-container" }, [
              _c(
                "ul",
                { staticClass: "c-filter__list" },
                [
                  _vm._l(_vm.citiesList, function (city) {
                    return _vm.activeFilter === "plats"
                      ? _c("div", { staticClass: "c-filter__city-wrapper" }, [
                          _c(
                            "li",
                            {
                              key: city.id,
                              staticClass: "c-filter__city",
                              attrs: { value: city.id, name: city.name },
                              on: {
                                click: function ($event) {
                                  return _vm.applySelectedCity(city)
                                },
                              },
                            },
                            [
                              _c("input", {
                                attrs: {
                                  type: "radio",
                                  id: city.id,
                                  name: city.name,
                                },
                                domProps: { checked: city.isActive },
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: city.name } }, [
                                _vm._v(_vm._s(city.name)),
                              ]),
                              _vm._v(" "),
                              _vm._l(city.areaReferences, function (area) {
                                return _c(
                                  "div",
                                  {
                                    key: area.id,
                                    staticClass: "c-area-filter",
                                    attrs: { value: area.id, name: area.name },
                                    on: {
                                      click: function ($event) {
                                        return _vm.applySelectedArea(
                                          city.id,
                                          area
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("input", {
                                      attrs: { type: "radio", id: area.name },
                                      domProps: { checked: area.isActive },
                                    }),
                                    _vm._v(" "),
                                    _c("label", { attrs: { for: area.name } }, [
                                      _vm._v(_vm._s(area.name)),
                                    ]),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ])
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.departmentsList, function (department) {
                    return _vm.activeFilter === "departments"
                      ? _c(
                          "div",
                          { staticClass: "c-filter-department-wrapper" },
                          [
                            _c(
                              "li",
                              {
                                key: department.id,
                                staticClass: "c-filter__city",
                                attrs: {
                                  value: department.id,
                                  name: department.name,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectDepartment(department)
                                  },
                                },
                              },
                              [
                                _c("input", {
                                  attrs: {
                                    type: "radio",
                                    id: department.id,
                                    name: department.name,
                                  },
                                  domProps: { checked: department.isActive },
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  { attrs: { for: department.name } },
                                  [_vm._v(_vm._s(department.name))]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e()
                  }),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "c-filter__button-container" }, [
              _c(
                "button",
                {
                  staticClass: "c-filter__button-cancel",
                  on: {
                    click: function ($event) {
                      _vm.showFilters = !_vm.showFilters
                    },
                  },
                },
                [_vm._v("\n        Avbryt\n      ")]
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }