<template>
    <div class="c-filter__sticky-wrapper">
        <div class="c-filter__show-filters-banner" v-if="!showFilters && !isDesktop">
            <div>
                <button class="c-filter__show-filters-button" @click="showMobileFilters">Visa alla filter
                    <div aria-hidden="true" class="c-icon c-icon--xs c-filter-dropdown__toggle-icon">
                        <svg v-if="!dropdownOpen"><use :xlink:href="`#icon-plus`"></use></svg>
                    </div>
                </button>
            </div>
            <section class="c-filter__hits-mobile">
                <h3 class="c-filter__hits-heading"><span class="c-filter__hits-number">{{ objects.length }}</span> Lokaler</h3>
            </section>
        </div>
        <div class="c-filter" :class="{'c-filter__mobile': !isDesktop }" v-if="showFilters || isDesktop">
            <div class="c-filter__wrapper" :class="{'c-filter__wrapper-mobile': !isDesktop}">
                <div class="c-filter__content-wrapper o-width-limiter--full">
                    <div class="c-filter__dropdown-wrapper">
                        <v-filter-dropdown @populate-filter="handleFilterData" tag="plats" :button-text="placesButtonText" :dropdown-open="this.activeFilter === 'plats'">
                            <ul class="c-filter-dropdown__list" v-if="this.activeFilter === 'plats'" v-click-outside="handleClickOutside">
    
                                <div v-if="activeFilter === 'plats'" class="c-filter__city-wrapper" v-for="city in cities">
                                    <li class="c-filter__city">
                                        <label @click="addRegions(city)">
                                            <input type="checkbox" :value="city.value" v-model="selectedPlaces">
                                            {{ city.display }}
                                        </label>
                                    </li>
                                    <li class="c-filter__region" v-for="region in regions" v-if="region.group === city.display">
                                        <div >
                                            <label>
                                                <input type="checkbox" :value="region.value" v-model="selectedPlaces">
                                                {{ region.display }}
                                            </label>
                                        </div>
                                    </li>
                                </div>
    
                            </ul>
                        </v-filter-dropdown>
                        <v-filter-dropdown @populate-filter="handleFilterData" tag="lokal" :button-text="typeOfBuildingButtonText" :dropdown-open="this.activeFilter === 'lokal'" >
                            <ul class="c-filter-dropdown__list" v-if="this.activeFilter === 'lokal'" v-click-outside="handleClickOutside">
                                <div v-if="activeFilter === 'lokal'" class="c-filter__place-wrapper" v-for="premise in premiseType">
                                    <li class="c-filter__place">
                                        <label>
                                            <input type="checkbox" :value="premise.value" v-model="selectedTypesOfBuildings">
                                            {{ premise.display }}
                                        </label>
                                    </li>
                                </div>    
                            </ul>
                        </v-filter-dropdown>
                        <v-filter-dropdown @populate-filter="handleFilterData" tag="yta" :button-text="buildingSizeText" :dropdown-open="this.activeFilter === 'yta'">
                            <ul class="c-filter-dropdown__list" v-if="this.activeFilter === 'yta'" v-click-outside="handleClickOutside">
                                <div v-if="activeFilter === 'yta'">
                                    <div class="c-filter__size-input-block">
                                        <div class="c-filter__size-input-heading">
                                            Välj önskad yta
                                        </div>
                                        <div class="c-filter__size-input-wrapper">
                                            <div class="c-filter__size-input-container">
                                                <div class="c-filter__size-input-tag">Min</div>
                                                <input type="number" class="c-filter__size-input" min="0" placeholder="0" v-model="minSize">
                                                <span class="c-filter__size-input-m2-tag">m<sup>2</sup></span>
                                            </div>
                                            <div aria-hidden="true" class="c-icon c-icon--xs c-filter-dropdown__toggle-icon">
                                                <svg><use :xlink:href="`#icon-minus`"></use></svg>
                                            </div>
                                            <div class="c-filter__size-input-container">
                                                <div class="c-filter__size-input-tag">Max</div>
                                                <input type="number" class="c-filter__size-input" min="0" placeholder="0" v-model="maxSize">
                                                <span class="c-filter__size-input-m2-tag">m<sup>2</sup></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ul>
                        </v-filter-dropdown>
                        <section class="c-filter__hits">
                            <h3 class="c-filter__hits-heading"><span class="c-filter__hits-number">{{ objects.length }}</span> Lokaler</h3>
                        </section>
                    </div>
                </div>
            </div>
            <div class="c-filter__sorting-container o-width-limiter--full" :class="{'c-filter__sorting-container-mobile': !isDesktop}">
                <div>
                    <span>Sortera på:</span>
                    <button class="c-filter__sorting-select" id="sorting-select" @click="showSortingOptions = !showSortingOptions">
                        {{ activeSorting.display }}
                        <div aria-hidden="true" class="c-icon c-icon--xs c-filter-dropdown__toggle-icon">
                            <svg><use :xlink:href="`#icon-select-arrow`"></use></svg>
                        </div>
                    </button>
                    <ul v-click-outside="handleClickOutside" v-if="showSortingOptions" class="c-filter__sorting-options-dropdown">
                        <li class="c-filter__sorting-option" :class="{'c-filter__sorting-option--active': option === activeSorting}" v-for="(option, index) in sortOptions" :key="index" @click="selectSortingOption(option)">
                            <div v-if="option === activeSorting" aria-hidden="true" class="c-icon c-icon--xs c-filter__check-icon">
                                <svg><use :xlink:href="`#icon-check`"></use></svg>
                            </div>
                            {{ option.display }}
                        </li>
                    </ul>
                    
                </div>
                <button class="c-filter__sorting-reset" @click="resetFilter">Återställ val</button>
            </div>
            <div class="c-filter__choices-container">
                <ul class="c-filter__list">
                    <div v-if="activeFilter === 'plats'" class="c-filter__city-wrapper" v-for="city in cities">
                        <!-- {{ city.display}} -->
                        <li class="c-filter__city">
                            <label @click="addRegionsMobile(city)">
                                <input type="checkbox" :value="city.value" v-model="selectedPlacesMobile">
                                {{ city.display }}
                            </label>
                        </li>
                        <li class="c-filter__region" v-for="region in regions" v-if="region.group === city.display">
                            <label>
                                <input type="checkbox" :value="region.value" v-model="selectedPlacesMobile">
                                {{ region.display }}
                            </label>
                        </li>
                    </div>
                    <div v-if="activeFilter === 'lokal'" class="c-filter__place-wrapper" v-for="premise in premiseType">
                        <li class="c-filter__place">
                            <label>
                                <input type="checkbox" :value="premise.value" v-model="selectedTypesOfBuildingsMobile">
                                {{ premise.display }}
                            </label>
                        </li>
                    </div>
                    <div v-if="activeFilter === 'yta'">
                        <div class="c-filter__size-input-block">
                            <div class="c-filter__size-input-heading">
                                Välj önskad yta
                            </div>
                            <div class="c-filter__size-input-wrapper">
                                <div class="c-filter__size-input-container">
                                    <div class="c-filter__size-input-tag">Min</div>
                                    <input type="number" class="c-filter__size-input" placeholder="0" v-model="minSizeMobile">
                                    <span class="c-filter__size-input-m2-tag">m<sup>2</sup></span>
                                </div>
                                <div aria-hidden="true" class="c-icon c-icon--xs c-filter-dropdown__toggle-icon">
                                    <svg><use :xlink:href="`#icon-minus`"></use></svg>
                                </div>
                                <div class="c-filter__size-input-container">
                                    <div class="c-filter__size-input-tag">Max</div>
                                    <input type="number" class="c-filter__size-input" placeholder="0" v-model="maxSizeMobile">
                                    <span class="c-filter__size-input-m2-tag">m<sup>2</sup></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ul>
            </div>
            <div class="c-filter__button-container">
                <button class="c-filter__button-cancel" @click="showFilters = !showFilters">Avbryt</button>
                <button class="c-filter__button-save" @click="applyFiltersMobile">Applicera</button>
            </div>
        </div>
    </div>
</template>

<script>
import VFilterDropdown from './dropdown/VFilterDropdown.vue'
import vClickOutside from "v-click-outside";

export default {
    name: 'VFilter',
    directives: {
		clickOutside: vClickOutside.directive
	},
    components: {
        VFilterDropdown
    },
    
    props: {
        objects: {
            type: Array,
            default: () => []
        },
        multiSelectFilters: {
            type: Array,
            default: () => []
        },
        objectsFetched: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            cities: [],
            regions: [],
            premiseType: [],
            showSortingOptions: false,
            sortOptions: [
                {
                    display: 'Adress: A - Ö',
                    value: 1
                },
                {
                    display: 'Adress: Ö - A',
                    value: 2
                },
                {
                    display: 'Senaste objekt',
                    value: 3
                },
                {
                    display: 'Yta: minst',
                    value: 4,
                },
                {
                    display: 'Yta: störst',
                    value: 5
                }  
            ],
            activeSorting: {},
            activeFilter: '',
            selectedPlaces: [],
            selectedTypesOfBuildings: [],
            minSize: '',
            maxSize: '',
            selectedPlacesMobile: [],
            selectedTypesOfBuildingsMobile: [],
            minSizeMobile: '',
            maxSizeMobile: '',
            hits: [],
            showFilters: false,
            isDesktop: false
        }
    },

    methods: {
        handleFilterData(data) {
            let filter
            
            data.forEach(element => {
                filter = element.tag
            })
            if (window.screen.width >= 768 && this.activeFilter === filter) {
                this.activeFilter = ''
            } else if (window.screen.width >= 768 && this.activeFilter !== '') {
                this.activeFilter = filter
            } else if (window.screen.width >= 768 && this.activeFilter === '') {
                this.activeFilter = filter
            } else if (window.screen.width < 768) {
                this.activeFilter = filter
            }
        },
        selectSortingOption(option) {
            this.activeSorting = option
            this.showSortingOptions = false
        },
        addRegions(city) {
            this.regions.forEach(region => {
                if ((region.group === city.value) && (!this.selectedPlaces.includes(region.value)) && (!this.selectedPlaces.includes(city.value))) {
                    this.selectedPlaces.push(region.value)
                    } 
                else if (this.selectedPlaces.includes(city.value)) {
                    this.selectedPlaces.forEach((selected, index) => {
                        if (selected === region.value) {
                            this.selectedPlaces.splice(index, 1);
                        }
                    });
                }
            })
        },
        addRegionsMobile(city) {
            this.regions.forEach(region => {
                if ((region.group === city.value) && (!this.selectedPlacesMobile.includes(region.value)) && (!this.selectedPlacesMobile.includes(city.value))) {
                    this.selectedPlacesMobile.push(region.value)
                    } else if (this.selectedPlacesMobile.includes(city.value)) {
                        this.selectedPlacesMobile.forEach((selected, index) => {
                        if (selected === region.value) {
                        this.selectedPlacesMobile.splice(index, 1);
                        }
                    });
                }
            })
        },
        resetFilter() {
            this.activeSorting = this.sortOptions[2]
            this.selectedPlaces = []
            this.selectedPlacesMobile = []
            this.selectedTypesOfBuildings = []
            this.selectedTypesOfBuildingsMobile = []
            this.minSize = ''
            this.minSizeMobile = ''
            this.maxSize = ''
            this.maxSizeMobile = ''
            this.activeFilter = ''
            this.showFilters = false
        },
        setMultiSelectFilter(filter) {
			this.$emit('setMultiSelectFilter', filter);
		},
        setSelectedPlacesLength(length) {
            this.$emit('setSelectedPlacesLength', length)
        },
        setSelectedTypesOfBuildingsLength(length) {
            this.$emit('setSelectedTypesOfBuildingsLength', length)
        },
        setMinSize(size) {
            this.$emit('setMinSize', size)
        },
        setMaxSize(size) {
            this.$emit('setMaxSize', size)
        },
        setSortingOption(option) {
            this.$emit('setSortingOption', option)
        },
        checkScreenSize() {
            this.isDesktop = window.innerWidth >= 768; // Define the breakpoint where desktop starts (adjust as needed)
        },
        showMobileFilters() {
            this.activeFilter = 'plats'
            this.showFilters = true
        },
        applyFiltersMobile() {
            this.selectedPlaces = this.selectedPlacesMobile
            this.selectedTypesOfBuildings = this.selectedTypesOfBuildingsMobile
            this.minSize = this.minSizeMobile
            this.maxSize = this.maxSizeMobile
            this.showFilters = false
        },
        handleClickOutside() {
            if(this.activeFilter === 'plats' && this.isDesktop) {
                this.activeFilter = ''
            } else if (this.activeFilter === 'lokal' && this.isDesktop) {
                this.activeFilter = ''
            } else if (this.activeFilter === 'yta' && this.isDesktop) {
                this.activeFilter = ''
            }

            if (this.showSortingOptions && this.isDesktop) {
                this.showSortingOptions = false
            }
            
        },
        setQueryParams() {
			let selectedOptions = [];
			let url = window.location.toString();
            let cleanUrl = url.split('?')[0];

			this.selectedPlaces.filter(place => {
				selectedOptions.push(place);
			});

            this.selectedTypesOfBuildings.filter(premise => {
				selectedOptions.push(premise);
			});

            if (this.minSize !== '' || this.maxSize !== '') {
                selectedOptions.push('area:' + this.minSize, this.maxSize);
            }

			let newUrl = this.replaceUrlParam(cleanUrl, selectedOptions.join('%2C'));
			newUrl = new URL(newUrl);
			window.history.replaceState({}, '', newUrl.search);
            
		},
        replaceUrlParam(url, value) {
			if (value.length === 0) {
				value = '';
			}
			let pattern = new RegExp('\\b('+'=).*?(&|#|$)');
			if (url.search(pattern)>=0) {
				return url.replace(pattern,'$1' + value + '$2');
			}
			url = url.replace(/[?#]$/,'');
			return url + (url.indexOf('?')>0 ? '&' : '?') + '=' + value;
		},
        getQueryParamsFromURL () {
			let regex = new RegExp('[\\?&]' + '=([^&#]*)');
			let results = regex.exec(location.search);
			return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
		},
		setFilterFromParams (values) {
			values.forEach((value) => {
                    this.multiSelectFilters[0].items.forEach(item => {
                        if (item.value === value) {
                            this.selectedPlaces.push(value)
                            this.selectedPlacesMobile.push(value)
                        }
                    })
                    this.multiSelectFilters[1].items.forEach(item => {
                        if (item.value === value) {
                            this.selectedTypesOfBuildings.push(value)
                            this.selectedTypesOfBuildingsMobile.push(value)
                        }
                    })
                    if (value.includes("area:")) {
                    let areaValues = value.split(":")[1].split(",");
                    let minSize = areaValues[0].trim();
                    this.minSize = minSize
                    this.minSizeMobile = minSize
                    this.maxSize = values[values.length - 1]
                    this.maxSizeMobile = values[values.length - 1]
                }
			});
		}
    },
    computed: {
        placesButtonText() {
            if (this.selectedPlaces.length === 0) {
                return 'Välj område'
            } else {
                return this.selectedPlaces.length + ' valda'
            }
        },
        typeOfBuildingButtonText() {
            if (this.selectedTypesOfBuildings.length === 0) {
                return 'Välj lokal'
            } else {
                return this.selectedTypesOfBuildings.length + ' valda'
            }
        },
        buildingSizeText() {
            if(this.minSize === '' && (this.maxSize === '' || this.maxSize === '0')) {
                return 'Välj yta'
            }
            else {
                return this.minSize + ' - ' + this.maxSize + ' m2'
            }
        }
    },
    watch: {
        selectedPlaces() {
            this.regions.forEach(region => {
                if (!this.selectedPlaces.includes(region.value) && this.selectedPlaces.includes(region.group)) {
                    this.selectedPlaces = this.selectedPlaces.filter(place => place !== region.group)
                }
            }) 
            this.setSelectedPlacesLength(this.selectedPlaces.length)
            this.setMultiSelectFilter(this.selectedTypesOfBuildings.concat(this.selectedPlaces))
            this.setQueryParams();
        },
        selectedTypesOfBuildings() {
            this.setSelectedTypesOfBuildingsLength(this.selectedTypesOfBuildings.length)
            this.setMultiSelectFilter(this.selectedTypesOfBuildings.concat(this.selectedPlaces))
            this.setQueryParams();
        },
        minSize() {
            this.setMinSize(this.minSize)
            this.setMultiSelectFilter(this.selectedTypesOfBuildings.concat(this.selectedPlaces))
            this.setQueryParams();
        },
        maxSize() {
            this.setMaxSize(this.maxSize)
            this.setMultiSelectFilter(this.selectedTypesOfBuildings.concat(this.selectedPlaces))
            this.setQueryParams();
        },
        activeSorting() {
            this.setSortingOption(this.activeSorting)
        },
        activeFilter() {
            this.multiSelectFilters.forEach(filter => {
                if (filter.name === "location") {
                    filter.items.forEach(item => {
                        if(item.groupLabel && !this.cities.includes(item)) {
                            this.cities.push(item)
                        } else if(!this.regions.includes(item)){
                            this.regions.push(item)
                        }
                        
                    })
                } else if (filter.name === "premisetype") {
                    filter.items.forEach(item => {
                        if(!this.premiseType.includes(item)) {
                            this.premiseType.push(item)
                        }
                    })
                }
            })
        },
        showFilters() {
            document.querySelector('body').style.overflow = this.showFilters ? 'hidden' : 'auto';
        },
        objectsFetched() {
            let queryParams = this.getQueryParamsFromURL();
            queryParams = decodeURI(queryParams);

            if (queryParams) {
                let values = queryParams.split(",");
                this.setFilterFromParams(values);
            }
            this.setQueryParams()
        }
    },  
    created() {
        this.activeSorting = this.sortOptions[2];
        this.checkScreenSize(); // Check initial screen size
        window.addEventListener('resize', this.checkScreenSize); // Add event listener for resize
    },
    destroyed() {
        window.removeEventListener('resize', this.checkScreenSize)
    },
}
</script>