<script>
import {
    mapGetters
} from 'vuex';
export default {
    name: 'VObjectListingDataProvider',
    computed: mapGetters('objects', {
		currentObjects: 'currentObjects',
		multiSelectFilters: 'multiSelectFilters',
		rangeFilters: 'rangeFilters',
		loading: 'loading',
		filtersApplied: 'filtersApplied',
	}),
	data() {
		return {
			queryParams: [],
		}
	},
	props: {
		apiUrl: {
			type: String,
			default: ''
		},
		isLoading: {
			type: Boolean,
			default: false
		}
	},
    methods: {
		setMultiSelectFilter(filter) {
			this.$store.dispatch('objects/setMultiSelectFilter', filter);
		},
		setRangeFilter(range) {
			this.$store.dispatch('objects/setRangeFilter', range);
		},
		resetAllFilters() {
			this.$store.dispatch('objects/resetAllFilters');
		}
    },
    render() {
        if (!this.$scopedSlots.default) {
            return;
        }

        const slotScope = {
			objects: this.objects,
			currentObjects: this.currentObjects,
			countObjects: this.currentObjects.length,
			multiSelectFilters: this.multiSelectFilters,
			setMultiSelectFilter: this.setMultiSelectFilter,
			rangeFilters: this.rangeFilters,
			isLoading: this.loading,
			setRangeFilter: this.setRangeFilter,
			resetAllFilters: this.resetAllFilters,
			filtersApplied: this.filtersApplied
		};

        return this.$scopedSlots.default(slotScope);
    },
    created() {
		this.$store.dispatch('objects/fetchObjects', {
			apiUrl: this.apiUrl
		});
	}
}
</script>
