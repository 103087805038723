var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-mobile-nav__item" }, [
    _c(
      "a",
      {
        staticClass: "c-button c-mobile-nav__action",
        attrs: { href: _vm.url },
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }