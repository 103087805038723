var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "offices-container o-width-limiter--narrow" },
    _vm._l(_vm.officeData, function (office, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "offices-item o-width-limiter--narrow",
          attrs: { office: office },
        },
        [_c("div", { domProps: { innerHTML: _vm._s(office.text) } })]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }