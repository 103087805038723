var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-multi-select",
      class: { "is-open": _vm.isOpen },
      attrs: { id: _vm.id },
    },
    [
      _c(
        "button",
        {
          ref: "trigger",
          staticClass: "c-multi-select__trigger",
          attrs: { "aria-haspopup": "listbox", "aria-expanded": "false" },
          on: {
            click: function ($event) {
              return _vm.toggleSelect()
            },
            keydown: function ($event) {
              return _vm.triggerKeyPress($event)
            },
          },
        },
        [
          _c("span", { staticClass: "c-multi-select__container" }, [
            _c("span", { staticClass: "c-multi-select__label" }, [
              _vm._v(_vm._s(_vm.label)),
            ]),
            _vm._v(" "),
            _vm.numSelectedItems !== 0
              ? _c("span", { staticClass: "c-multi-select__active" }, [
                  _vm._v(
                    _vm._s(_vm.numSelectedItems) + " " + _vm._s(_vm.countLabel)
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "c-icon c-multi-select__icon",
              attrs: { "aria-hidden": "true" },
            },
            [
              _c("svg", [
                _c("use", { attrs: { "xlink:href": "#icon-select-arrow" } }),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ul",
        {
          ref: "list",
          staticClass: "c-multi-select__list",
          attrs: {
            "aria-activedescendant": _vm.id + "-option-" + _vm.selectedIndex,
            role: "listbox",
            tabindex: "-1",
          },
          on: {
            focusout: function ($event) {
              return _vm.toggleSelect()
            },
            keydown: function ($event) {
              return _vm.listKeyPress($event)
            },
          },
        },
        _vm._l(_vm.options, function (option, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "c-multi-select__option",
              class: [
                index === _vm.selectedIndex ? "is-active" : "",
                option.groupLabel ? "c-multi-select__group-label" : "",
              ],
              attrs: {
                "aria-selected": option.checked ? "true" : "false",
                "aria-labelledby": _vm.id + "-label-" + index,
                id: _vm.id + "-option-" + index,
                role: "option",
              },
            },
            [
              _c("input", {
                staticClass: "c-custom-checkbox__input",
                attrs: { type: "checkbox", id: _vm.id + "-checkbox-" + index },
                domProps: {
                  value: option.value,
                  checked: option.checked ? true : false,
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "c-custom-checkbox__label c-multi-select__checkbox-label",
                  class: { "is-active": option.checked },
                  attrs: {
                    for: _vm.id + "-checkbox-" + index,
                    id: _vm.id + "-label-" + index,
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.selectItem(index, option.value)
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\t" + _vm._s(option.display) + "\n\t\t\t\t")]
              ),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }