<template>
  <div id="contactUsForm" class="c-contact-us-form">
    <slot />
  </div>
</template>

<script>
export default {
  name: "VContactForm",
};
</script>
