var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-filters-wrapper" }, [
    _c(
      "div",
      { staticClass: "c-filters-wrapper__inner o-width-limiter--full" },
      [
        _vm._l(_vm.multiSelectFilters, function (filter) {
          return [
            filter && filter.items.length > 0
              ? _c("v-multi-select", {
                  attrs: {
                    label: filter.displayName,
                    id: filter.name,
                    options: filter.items,
                  },
                  on: { optionSelected: _vm.setMultiSelectFilter },
                })
              : _vm._e(),
          ]
        }),
        _vm._v(" "),
        _vm._l(_vm.rangeFilters, function (filter) {
          return [
            _c("v-range-slider", {
              attrs: {
                label: "Kvadratmeter",
                id: filter.name,
                "min-value": filter.minValue,
                "max-value": filter.maxValue,
                "adjusted-max": filter.adjustedMax,
                "selected-min": filter.selectedMin,
                "selected-max": filter.selectedMax,
                step: 1,
              },
              on: { rangeSelected: _vm.setRangeFilter },
            }),
          ]
        }),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "c-filters-wrapper__reset",
            style: `visibility: ${_vm.filtersApplied ? "visible" : "hidden"}`,
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.resetAllFilters.apply(null, arguments)
              },
            },
          },
          [_vm._v(_vm._s(_vm.resetAllText))]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }