<template> 
    <div class="c-main-nav__item-container" :class="{'c-main-nav__item-row-expanded': level !== 1}">
        <div class="c-main-nav__item">
            <div class="c-main-nav__item-row" :class="{'c-main-nav__item-row-border-bottom': expanded && level === 1}">
                <a
                :href="`${anchor}`"
                class="c-main-nav__link"
                :style="[
                    active ? { fontWeight: '500', color: '#5c8fb4' } : { fontWeight: '200' },
                    expanded ? {'fontWeight': '500'} : {'fontWeight': '200'},
                    level === 1 ? {'padding': '16px 0'} : {'padding': '12px 0'},
                    ]">
                    {{ title }}
                </a>
                <button v-if="expandable" class="c-main-nav__expand-item-button" @click="expanded = !expanded">
                    <span class="t-visually-hidden">Expandera menyrad</span>
                    <span aria-hidden="true" class="c-icon c-icon--xs c-main-nav__plus-icon">
                        <svg v-if="!expanded"><use :xlink:href="`#icon-plus`"></use></svg>
                        <svg v-else><use :xlink:href="`#icon-minus`"></use></svg>
                    </span>
                </button>
                <div v-else></div>
            </div>
        </div>
        <slot v-if="expanded"/>
    </div>
</template>

<script>

export default {
    name: 'VMainNavItem',
    components: {
    },
    data() {
        return {
            levelClass: '',
            expanded: false
        }
    },
    props: {
		anchor: {
			type: String,
			required: true
		},
        expandable: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            required: true
        },
        level: {
            type: Number
        },
        active: {
            type: Boolean
        }
	},

    computed: {
        setLevelClass() {
            if(this.expandable) {
                return '-level'
            } else {
                return ''
            }
        }
    },
    mounted() {
        if ((window.location.href.indexOf(this.anchor) > -1) && (!this.active)){
            this.expanded = true
        }
    }
}

</script>
