<template>
    <div>
    <VContactFilter
      :cities="cities"
      :departments="departments"
      @filter-changed="handleFilterChange"
    />
    <div class="c-contactworkerlist-container">
      <section class="c-contact-worker__hits-heading o-width-limiter">
        <div class="c-contact-numberofcoworkers">
          <span class="c-filter__hits-number">{{
            paginationObject.total
          }}</span>
          resultat
        </div>
      </section>
      <div class="c-contact-worker__list o-width-limiter">
        <VContactCoworkerItem
          v-for="(contact, index) in contactWorkers"
          :contact="contact"
          :key="index"
        />
      </div>
      <div class="c-contact-worker__pagination-wrapper">
        <p class="c-contact-worker__pagination-heading">
          Visar
          <span class="c-contact-worker__display-items">{{
            paginationObject.display
          }}</span>
          av
          <span class="c-contact-worker__total-items">{{
            paginationObject.total
          }}</span>
          medarbetare
        </p>
        <a
          v-if="paginationObject.display < paginationObject.total"
          @click="loadMore"
          class="c-button c-hero__button--light"
        >
          Se fler medarbetare
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import VContactCoworkerItem from "./VContactCoworkerItem.vue";
import VContactFilter from "../../filter/VContactFilter.vue";
import axios from "axios";

export default {
  name: "VContactCoworkerList",
  props: {
    contacts: {
      type: Array,
    },
    cities: {
      type: Array,
    },
    departments: {
      type: Array,
    },
    pagination: {
      type: Object,
      default: function () {
        return { total: 0, display: 0, currentPage: 1 };
      },
    },
  },
  data() {
    return {
      showEmail: false,
      showNumber: false,
      contactWorkers: this.contacts,
      htmlContent: "",
      apiUrl: "",
      filterData: {
        searchValue: "",
        selectedCity: "",
        selectedDepartment: "",
      },
      paginationObject: this.pagination,
    };
  },
  methods: {
    handleFilterChange(filterData) {
      this.paginationObject.currentPage = 1;
      this.filterData = filterData;
      this.fetchContacts();
    },
    loadMore() {
      this.paginationObject.currentPage++;
      this.fetchContacts();
    },
    fetchContacts() {
      const queryString = `?CookieRefId=8702&searchString=${
        this.filterData.searchValue || ""
      }&districtPageId=${this.filterData.selectedCity || ""}&departmentsId=${
        this.filterData.selectedDepartment || ""
      }&page=${this.paginationObject.currentPage}`;

      const fullUrl = this.apiUrl + queryString;
      axios
        .get(fullUrl)
        .then((response) => {
          let responseData;
          try {
            responseData = JSON.parse(response.data);
          } catch {
            responseData = response.data;
          }

          if (this.paginationObject.currentPage == 1) {
            this.contactWorkers = responseData.contacts;
          } else {
            this.contactWorkers.push(...responseData.contacts);
          }
          this.paginationObject.total = responseData.pagination.total;
          this.paginationObject.display = responseData.pagination.display;
          this.paginationObject.currentPage =
            responseData.pagination.currentPage;
        })
        .catch((error) => {
          console.error("Error fetching contacts:", error);
        });
    }
  },

  components: { VContactCoworkerItem, VContactFilter },
  created() {
    this.apiUrl = new URL(
      "/sv/kontakta-oss/GetFilteredContacts",
      window.location.href
    ).href;
  },
};
</script>
