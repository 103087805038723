var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-filter__sticky-wrapper" }, [
    !_vm.showFilters && !_vm.isDesktop
      ? _c("div", { staticClass: "c-filter__show-filters-banner" }, [
          _c("div", [
            _c(
              "button",
              {
                staticClass: "c-filter__show-filters-button",
                on: { click: _vm.showMobileFilters },
              },
              [
                _vm._v("Visa alla filter\n                "),
                _c(
                  "div",
                  {
                    staticClass:
                      "c-icon c-icon--xs c-filter-dropdown__toggle-icon",
                    attrs: { "aria-hidden": "true" },
                  },
                  [
                    !_vm.dropdownOpen
                      ? _c("svg", [
                          _c("use", { attrs: { "xlink:href": `#icon-plus` } }),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("section", { staticClass: "c-filter__hits-mobile" }, [
            _c("h3", { staticClass: "c-filter__hits-heading" }, [
              _c("span", { staticClass: "c-filter__hits-number" }, [
                _vm._v(_vm._s(_vm.objects.length)),
              ]),
              _vm._v(" Lokaler"),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showFilters || _vm.isDesktop
      ? _c(
          "div",
          {
            staticClass: "c-filter",
            class: { "c-filter__mobile": !_vm.isDesktop },
          },
          [
            _c(
              "div",
              {
                staticClass: "c-filter__wrapper",
                class: { "c-filter__wrapper-mobile": !_vm.isDesktop },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "c-filter__content-wrapper o-width-limiter--full",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "c-filter__dropdown-wrapper" },
                      [
                        _c(
                          "v-filter-dropdown",
                          {
                            attrs: {
                              tag: "plats",
                              "button-text": _vm.placesButtonText,
                              "dropdown-open": this.activeFilter === "plats",
                            },
                            on: { "populate-filter": _vm.handleFilterData },
                          },
                          [
                            this.activeFilter === "plats"
                              ? _c(
                                  "ul",
                                  {
                                    directives: [
                                      {
                                        name: "click-outside",
                                        rawName: "v-click-outside",
                                        value: _vm.handleClickOutside,
                                        expression: "handleClickOutside",
                                      },
                                    ],
                                    staticClass: "c-filter-dropdown__list",
                                  },
                                  _vm._l(_vm.cities, function (city) {
                                    return _vm.activeFilter === "plats"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "c-filter__city-wrapper",
                                          },
                                          [
                                            _c(
                                              "li",
                                              { staticClass: "c-filter__city" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addRegions(
                                                          city
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.selectedPlaces,
                                                          expression:
                                                            "selectedPlaces",
                                                        },
                                                      ],
                                                      attrs: {
                                                        type: "checkbox",
                                                      },
                                                      domProps: {
                                                        value: city.value,
                                                        checked: Array.isArray(
                                                          _vm.selectedPlaces
                                                        )
                                                          ? _vm._i(
                                                              _vm.selectedPlaces,
                                                              city.value
                                                            ) > -1
                                                          : _vm.selectedPlaces,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$a =
                                                              _vm.selectedPlaces,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v =
                                                                city.value,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                (_vm.selectedPlaces =
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ]))
                                                            } else {
                                                              $$i > -1 &&
                                                                (_vm.selectedPlaces =
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    ))
                                                            }
                                                          } else {
                                                            _vm.selectedPlaces =
                                                              $$c
                                                          }
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(city.display) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.regions,
                                              function (region) {
                                                return region.group ===
                                                  city.display
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "c-filter__region",
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c("label", [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.selectedPlaces,
                                                                  expression:
                                                                    "selectedPlaces",
                                                                },
                                                              ],
                                                              attrs: {
                                                                type: "checkbox",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  region.value,
                                                                checked:
                                                                  Array.isArray(
                                                                    _vm.selectedPlaces
                                                                  )
                                                                    ? _vm._i(
                                                                        _vm.selectedPlaces,
                                                                        region.value
                                                                      ) > -1
                                                                    : _vm.selectedPlaces,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$a =
                                                                        _vm.selectedPlaces,
                                                                      $$el =
                                                                        $event.target,
                                                                      $$c =
                                                                        $$el.checked
                                                                          ? true
                                                                          : false
                                                                    if (
                                                                      Array.isArray(
                                                                        $$a
                                                                      )
                                                                    ) {
                                                                      var $$v =
                                                                          region.value,
                                                                        $$i =
                                                                          _vm._i(
                                                                            $$a,
                                                                            $$v
                                                                          )
                                                                      if (
                                                                        $$el.checked
                                                                      ) {
                                                                        $$i <
                                                                          0 &&
                                                                          (_vm.selectedPlaces =
                                                                            $$a.concat(
                                                                              [
                                                                                $$v,
                                                                              ]
                                                                            ))
                                                                      } else {
                                                                        $$i >
                                                                          -1 &&
                                                                          (_vm.selectedPlaces =
                                                                            $$a
                                                                              .slice(
                                                                                0,
                                                                                $$i
                                                                              )
                                                                              .concat(
                                                                                $$a.slice(
                                                                                  $$i +
                                                                                    1
                                                                                )
                                                                              ))
                                                                      }
                                                                    } else {
                                                                      _vm.selectedPlaces =
                                                                        $$c
                                                                    }
                                                                  },
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  region.display
                                                                ) +
                                                                "\n                                        "
                                                            ),
                                                          ]),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e()
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e()
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-filter-dropdown",
                          {
                            attrs: {
                              tag: "lokal",
                              "button-text": _vm.typeOfBuildingButtonText,
                              "dropdown-open": this.activeFilter === "lokal",
                            },
                            on: { "populate-filter": _vm.handleFilterData },
                          },
                          [
                            this.activeFilter === "lokal"
                              ? _c(
                                  "ul",
                                  {
                                    directives: [
                                      {
                                        name: "click-outside",
                                        rawName: "v-click-outside",
                                        value: _vm.handleClickOutside,
                                        expression: "handleClickOutside",
                                      },
                                    ],
                                    staticClass: "c-filter-dropdown__list",
                                  },
                                  _vm._l(_vm.premiseType, function (premise) {
                                    return _vm.activeFilter === "lokal"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "c-filter__place-wrapper",
                                          },
                                          [
                                            _c(
                                              "li",
                                              {
                                                staticClass: "c-filter__place",
                                              },
                                              [
                                                _c("label", [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.selectedTypesOfBuildings,
                                                        expression:
                                                          "selectedTypesOfBuildings",
                                                      },
                                                    ],
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      value: premise.value,
                                                      checked: Array.isArray(
                                                        _vm.selectedTypesOfBuildings
                                                      )
                                                        ? _vm._i(
                                                            _vm.selectedTypesOfBuildings,
                                                            premise.value
                                                          ) > -1
                                                        : _vm.selectedTypesOfBuildings,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.selectedTypesOfBuildings,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v =
                                                              premise.value,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              (_vm.selectedTypesOfBuildings =
                                                                $$a.concat([
                                                                  $$v,
                                                                ]))
                                                          } else {
                                                            $$i > -1 &&
                                                              (_vm.selectedTypesOfBuildings =
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  ))
                                                          }
                                                        } else {
                                                          _vm.selectedTypesOfBuildings =
                                                            $$c
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(premise.display) +
                                                      "\n                                    "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e()
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-filter-dropdown",
                          {
                            attrs: {
                              tag: "yta",
                              "button-text": _vm.buildingSizeText,
                              "dropdown-open": this.activeFilter === "yta",
                            },
                            on: { "populate-filter": _vm.handleFilterData },
                          },
                          [
                            this.activeFilter === "yta"
                              ? _c(
                                  "ul",
                                  {
                                    directives: [
                                      {
                                        name: "click-outside",
                                        rawName: "v-click-outside",
                                        value: _vm.handleClickOutside,
                                        expression: "handleClickOutside",
                                      },
                                    ],
                                    staticClass: "c-filter-dropdown__list",
                                  },
                                  [
                                    _vm.activeFilter === "yta"
                                      ? _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "c-filter__size-input-block",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "c-filter__size-input-heading",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        Välj önskad yta\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "c-filter__size-input-wrapper",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "c-filter__size-input-container",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "c-filter__size-input-tag",
                                                        },
                                                        [_vm._v("Min")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: _vm.minSize,
                                                            expression:
                                                              "minSize",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "c-filter__size-input",
                                                        attrs: {
                                                          type: "number",
                                                          min: "0",
                                                          placeholder: "0",
                                                        },
                                                        domProps: {
                                                          value: _vm.minSize,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.minSize =
                                                              $event.target.value
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "c-filter__size-input-m2-tag",
                                                        },
                                                        [
                                                          _vm._v("m"),
                                                          _c("sup", [
                                                            _vm._v("2"),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "c-icon c-icon--xs c-filter-dropdown__toggle-icon",
                                                      attrs: {
                                                        "aria-hidden": "true",
                                                      },
                                                    },
                                                    [
                                                      _c("svg", [
                                                        _c("use", {
                                                          attrs: {
                                                            "xlink:href": `#icon-minus`,
                                                          },
                                                        }),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "c-filter__size-input-container",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "c-filter__size-input-tag",
                                                        },
                                                        [_vm._v("Max")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: _vm.maxSize,
                                                            expression:
                                                              "maxSize",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "c-filter__size-input",
                                                        attrs: {
                                                          type: "number",
                                                          min: "0",
                                                          placeholder: "0",
                                                        },
                                                        domProps: {
                                                          value: _vm.maxSize,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.maxSize =
                                                              $event.target.value
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "c-filter__size-input-m2-tag",
                                                        },
                                                        [
                                                          _vm._v("m"),
                                                          _c("sup", [
                                                            _vm._v("2"),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c("section", { staticClass: "c-filter__hits" }, [
                          _c("h3", { staticClass: "c-filter__hits-heading" }, [
                            _c(
                              "span",
                              { staticClass: "c-filter__hits-number" },
                              [_vm._v(_vm._s(_vm.objects.length))]
                            ),
                            _vm._v(" Lokaler"),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "c-filter__sorting-container o-width-limiter--full",
                class: { "c-filter__sorting-container-mobile": !_vm.isDesktop },
              },
              [
                _c("div", [
                  _c("span", [_vm._v("Sortera på:")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "c-filter__sorting-select",
                      attrs: { id: "sorting-select" },
                      on: {
                        click: function ($event) {
                          _vm.showSortingOptions = !_vm.showSortingOptions
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.activeSorting.display) +
                          "\n                    "
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "c-icon c-icon--xs c-filter-dropdown__toggle-icon",
                          attrs: { "aria-hidden": "true" },
                        },
                        [
                          _c("svg", [
                            _c("use", {
                              attrs: { "xlink:href": `#icon-select-arrow` },
                            }),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.showSortingOptions
                    ? _c(
                        "ul",
                        {
                          directives: [
                            {
                              name: "click-outside",
                              rawName: "v-click-outside",
                              value: _vm.handleClickOutside,
                              expression: "handleClickOutside",
                            },
                          ],
                          staticClass: "c-filter__sorting-options-dropdown",
                        },
                        _vm._l(_vm.sortOptions, function (option, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              staticClass: "c-filter__sorting-option",
                              class: {
                                "c-filter__sorting-option--active":
                                  option === _vm.activeSorting,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.selectSortingOption(option)
                                },
                              },
                            },
                            [
                              option === _vm.activeSorting
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "c-icon c-icon--xs c-filter__check-icon",
                                      attrs: { "aria-hidden": "true" },
                                    },
                                    [
                                      _c("svg", [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href": `#icon-check`,
                                          },
                                        }),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(option.display) +
                                  "\n                    "
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "c-filter__sorting-reset",
                    on: { click: _vm.resetFilter },
                  },
                  [_vm._v("Återställ val")]
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "c-filter__choices-container" }, [
              _c(
                "ul",
                { staticClass: "c-filter__list" },
                [
                  _vm._l(_vm.cities, function (city) {
                    return _vm.activeFilter === "plats"
                      ? _c(
                          "div",
                          { staticClass: "c-filter__city-wrapper" },
                          [
                            _c("li", { staticClass: "c-filter__city" }, [
                              _c(
                                "label",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addRegionsMobile(city)
                                    },
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedPlacesMobile,
                                        expression: "selectedPlacesMobile",
                                      },
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      value: city.value,
                                      checked: Array.isArray(
                                        _vm.selectedPlacesMobile
                                      )
                                        ? _vm._i(
                                            _vm.selectedPlacesMobile,
                                            city.value
                                          ) > -1
                                        : _vm.selectedPlacesMobile,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedPlacesMobile,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = city.value,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedPlacesMobile =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedPlacesMobile = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedPlacesMobile = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(city.display) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.regions, function (region) {
                              return region.group === city.display
                                ? _c(
                                    "li",
                                    { staticClass: "c-filter__region" },
                                    [
                                      _c("label", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.selectedPlacesMobile,
                                              expression:
                                                "selectedPlacesMobile",
                                            },
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            value: region.value,
                                            checked: Array.isArray(
                                              _vm.selectedPlacesMobile
                                            )
                                              ? _vm._i(
                                                  _vm.selectedPlacesMobile,
                                                  region.value
                                                ) > -1
                                              : _vm.selectedPlacesMobile,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.selectedPlacesMobile,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = region.value,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.selectedPlacesMobile =
                                                      $$a.concat([$$v]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.selectedPlacesMobile =
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                }
                                              } else {
                                                _vm.selectedPlacesMobile = $$c
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(region.display) +
                                            "\n                        "
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e()
                            }),
                          ],
                          2
                        )
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.premiseType, function (premise) {
                    return _vm.activeFilter === "lokal"
                      ? _c("div", { staticClass: "c-filter__place-wrapper" }, [
                          _c("li", { staticClass: "c-filter__place" }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedTypesOfBuildingsMobile,
                                    expression:
                                      "selectedTypesOfBuildingsMobile",
                                  },
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  value: premise.value,
                                  checked: Array.isArray(
                                    _vm.selectedTypesOfBuildingsMobile
                                  )
                                    ? _vm._i(
                                        _vm.selectedTypesOfBuildingsMobile,
                                        premise.value
                                      ) > -1
                                    : _vm.selectedTypesOfBuildingsMobile,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a =
                                        _vm.selectedTypesOfBuildingsMobile,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = premise.value,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.selectedTypesOfBuildingsMobile =
                                            $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.selectedTypesOfBuildingsMobile =
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.selectedTypesOfBuildingsMobile = $$c
                                    }
                                  },
                                },
                              }),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(premise.display) +
                                  "\n                        "
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm.activeFilter === "yta"
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "c-filter__size-input-block" },
                          [
                            _c(
                              "div",
                              { staticClass: "c-filter__size-input-heading" },
                              [
                                _vm._v(
                                  "\n                            Välj önskad yta\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "c-filter__size-input-wrapper" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "c-filter__size-input-container",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "c-filter__size-input-tag",
                                      },
                                      [_vm._v("Min")]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.minSizeMobile,
                                          expression: "minSizeMobile",
                                        },
                                      ],
                                      staticClass: "c-filter__size-input",
                                      attrs: {
                                        type: "number",
                                        placeholder: "0",
                                      },
                                      domProps: { value: _vm.minSizeMobile },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.minSizeMobile =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm._m(0),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "c-icon c-icon--xs c-filter-dropdown__toggle-icon",
                                    attrs: { "aria-hidden": "true" },
                                  },
                                  [
                                    _c("svg", [
                                      _c("use", {
                                        attrs: { "xlink:href": `#icon-minus` },
                                      }),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "c-filter__size-input-container",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "c-filter__size-input-tag",
                                      },
                                      [_vm._v("Max")]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.maxSizeMobile,
                                          expression: "maxSizeMobile",
                                        },
                                      ],
                                      staticClass: "c-filter__size-input",
                                      attrs: {
                                        type: "number",
                                        placeholder: "0",
                                      },
                                      domProps: { value: _vm.maxSizeMobile },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.maxSizeMobile =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm._m(1),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "c-filter__button-container" }, [
              _c(
                "button",
                {
                  staticClass: "c-filter__button-cancel",
                  on: {
                    click: function ($event) {
                      _vm.showFilters = !_vm.showFilters
                    },
                  },
                },
                [_vm._v("Avbryt")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "c-filter__button-save",
                  on: { click: _vm.applyFiltersMobile },
                },
                [_vm._v("Applicera")]
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "c-filter__size-input-m2-tag" }, [
      _vm._v("m"),
      _c("sup", [_vm._v("2")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "c-filter__size-input-m2-tag" }, [
      _vm._v("m"),
      _c("sup", [_vm._v("2")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }