var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.classList }, [
    _c(
      "div",
      { staticClass: "o-width-limiter c-hero__inner c-contact-hero-wrapper" },
      [
        _c(
          "div",
          { staticClass: "c-hero__content c-hero__content--align-center" },
          [
            _c("h1", { staticClass: "c-hero__title" }, [
              _vm._v(_vm._s(_vm.heading)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "c-hero__text" }, [
              _c("p", { domProps: { innerHTML: _vm._s(_vm.preamble) } }),
            ]),
            _vm._v(" "),
            _vm.formHeading
              ? _c("div", { staticClass: "c-hero__text" }, [
                  _c("p", [_vm._v(_vm._s(_vm.formHeading))]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "c-button c-hero__button--light",
                      attrs: { href: _vm.buttonLink },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.buttonText) + "\n        "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }