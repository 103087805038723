<template>
  <div class="c-filter__sticky-wrapper">
    <div class="c-filter__show-filters-banner" v-if="!showFilters && !isDesktop">
      <div class="c-mobile-nav__search c-contact-filter__search">
        <label for="search-input" class="t-visually-hidden">S&#246;k</label>
        <input @keyup.enter="searchContacts" type="text" name="q" id="search-input mobile-search-bar input"
          placeholder="Sök på namn, roll eller ort..." v-model="searchValue" />
        <button v-if="searchValue" class="clear-button" @click="searchValue = ''">
          <span aria-hidden="true" class="c-icon c-icon--xxs c-main-nav__close-icon">
            <svg>
              <use xlink:href="#icon-close"></use>
            </svg>
          </span>
        </button>
        <div class="container-search-button">
          <button type="submit" class="c-main-nav__search-button c-contact-filter__button" @click="searchContacts">
            <span class="t-visually-hidden">S&#246;k</span>
            <span aria-hidden="true" class="c-icon c-icon--xs c-main-nav__search-icon">
              <svg>
                <use xlink:href="#icon-search"></use>
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div class="filter-results-container">
        <button @click="showMobileFilters" class="filter-button">
          <div class="container-filter-button">
            <span class="filter-text">Filtrera</span>
            <span aria-hidden="true" class="c-icon c-icon--xs slider-icon">
              <svg>
                <use xlink:href="#icon-sliders"></use>
              </svg>
            </span>
          </div>
        </button>
      </div>
    </div>
    <div class="c-filter c-contact-filter" :class="{ 'c-filter__mobile': !isDesktop }" v-if="showFilters || isDesktop">
      <div v-if="isDesktop" class="c-filter__wrapper c-contact-filter__wrapper">
        <div class="c-filter__content-wrapper c-contact-filter__content-wrapper o-width-limiter">
          <div class="c-filter__dropdown-wrapper c-contact-filter__dropdown-wrapper">
            <v-filter-dropdown @populate-filter="handleFilterData" tag="plats"
              :button-text="selectedCity.name ? selectedCity.name : ''" :dropdown-open="this.activeFilter === 'plats'">
              <ul class="c-filter-dropdown__list" v-if="this.activeFilter === 'plats'"
                v-click-outside="handleClickOutside">
                <div v-if="activeFilter === 'plats'" class="c-filter__city-wrapper">
                  <li v-for="city in citiesList" :value="city.id" :key="city.id" class="c-filter__city"
                    @click="selectCity(city)">
                    <span>{{ city.name }}</span>
                    <p v-for="area in city.areaReferences" :value="area.id" :key="area.id" class="c-filter__city"
                      @click="selectCity(area)">
                      {{ area.name }}
                    </p>
                  </li>
                </div>
              </ul>
            </v-filter-dropdown>
            <v-filter-dropdown @populate-filter="handleFilterData" tag="departments"
              :button-text="selectedDepartment.name ? selectedDepartment.name : ''"
              :dropdown-open="this.activeFilter === 'departments'">
              <ul class="c-filter-dropdown__list" v-if="this.activeFilter === 'departments'"
                v-click-outside="handleClickOutside">
                <div v-if="activeFilter === 'departments'" class="c-filter__city-wrapper c-filter-department-wrapper">
                  <li v-for="department in departmentsList" :value="department.id" :key="department.id"
                    class="c-filter__city" @click="selectDepartment(department)">
                    <span>{{ department.name }}</span>
                  </li>
                </div>
              </ul>
            </v-filter-dropdown>
            <div class="c-main-nav__search c-contact-filter__search">
              <label for="search-input" class="t-visually-hidden">S&#246;k</label>
              <input type="text" name="q" id="search-input" placeholder="Sök på namn, roll eller ort..."
                @keyup.enter="searchContacts" v-model="searchValue" />
              <button v-if="searchValue" class="clear-button" @click="searchValue = ''">
                <span aria-hidden="true" class="c-icon c-icon--xxs c-main-nav__close-icon">
                  <svg>
                    <use xlink:href="#icon-close"></use>
                  </svg>
                </span>
              </button>
              <div class="container-search-button">
                <button type="submit" class="c-main-nav__search-button c-contact-filter__button" @click="searchContacts">
                  <span class="t-visually-hidden">S&#246;k</span>
                  <span aria-hidden="true" class="c-icon c-icon--xs c-main-nav__search-icon">
                    <svg>
                      <use xlink:href="#icon-search"></use>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isDesktop" class="c-filter__wrapper c-mobile-filter__wrapper">
        <div class="c-filter__content-wrapper c-mobile-filter__content-wrapper o-width-limiter">
          <div class="c-filter__dropdown-wrapper c-mobile-filter__dropdown-wrapper">
            <v-filter-dropdown @populate-filter="handleFilterData" tag="plats"
              :button-text="selectedCity.name ? selectedCity.name : ''" :dropdown-open="this.activeFilter === 'plats'">
              <ul class="c-filter-dropdown__list" v-if="this.activeFilter === 'plats'"
                v-click-outside="handleClickOutside">
                <div v-if="activeFilter === 'plats'" class="c-filter__city-wrapper">
                  <li v-for="city in citiesList" :value="city.id" :key="city.id" class="c-filter__city"
                    @click="selectCity(city)">
                    <span>{{ city.name }}</span>
                  </li>
                </div>
              </ul>
            </v-filter-dropdown>
            <v-filter-dropdown @populate-filter="handleFilterData" tag="departments"
              :button-text="selectedDepartment.name ? selectedDepartment.name : ''"
              :dropdown-open="this.activeFilter === 'departments'">
              <ul class="c-filter-dropdown__list" v-if="this.activeFilter === 'departments'"
                v-click-outside="handleClickOutside">
                <div v-if="activeFilter === 'departments'" class="c-filter__city-wrapper c-filter-department-wrapper">
                  <li v-for="department in departmentsList" :value="department.id" :key="department.id"
                    class="c-filter__city" @click="selectDepartment(department)">
                    <span>{{ department.name }}</span>
                  </li>
                </div>
              </ul>
            </v-filter-dropdown>
          </div>
        </div>
      </div>
      <div class="c-filter__choices-container">
        <ul class="c-filter__list">
          <div v-if="activeFilter === 'plats'" class="c-filter__city-wrapper" v-for="city in citiesList">
            <li :value="city.id" :key="city.id" class="c-filter__city" :name="city.name"  @click="applySelectedCity(city)">
              <input type="radio" :checked="city.isActive" :id="city.id" :name="city.name">
              <label :for="city.name">{{ city.name }}</label>
              <div v-for="area in city.areaReferences" :value="area.id" :name="area.name" :key="area.id" class="c-area-filter"
                @click="applySelectedArea(city.id, area)">
                <input type="radio" :checked="area.isActive" :id="area.name">
                <label :for="area.name">{{ area.name }}</label>
              </div>
            </li>
          </div>
          <div v-if="activeFilter === 'departments'" class="c-filter-department-wrapper"
            v-for="department in departmentsList">
            <li :value="department.id" :key="department.id" :name="department.name" class="c-filter__city" @click="selectDepartment(department)">
              <input type="radio" :checked="department.isActive" :id="department.id" :name="department.name">
              <label :for="department.name">{{ department.name }}</label>
            </li>
          </div>
        </ul>
      </div>
      <div class="c-filter__button-container">
        <button class="c-filter__button-cancel" @click="showFilters = !showFilters">
          Avbryt
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VFilterDropdown from "./dropdown/VFilterDropdown.vue";
import vClickOutside from "v-click-outside";
import { ref } from 'vue';

export default {
  name: "VFilter",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    VFilterDropdown,
  },

  props: {
    cities: {
      type: Array,
    },
    departments: {
      type: Array,
    },
  },

  data() {
    return {
      activeFilter: "",
      showFilters: false,
      isDesktop: false,
      selectedCity: {},
      selectedDepartment: {},
      searchValue: "",
      dropdownOpen: false,
      citiesList: this.cities,
      departmentsList: this.departments,
      allAreas: [],
      currentMobileFilter: "plats",
    };
  },

  methods: {
    handleFilterData(data) {
      let filter;

      data.forEach((element) => {
        filter = element.tag;
      });
      if (window.screen.width >= 768 && this.activeFilter === filter) {
        this.activeFilter = "";
      } else if (window.screen.width >= 768 && this.activeFilter !== "") {
        this.activeFilter = filter;
      } else if (window.screen.width >= 768 && this.activeFilter === "") {
        this.activeFilter = filter;
      } else if (window.screen.width < 768) {
        this.activeFilter = filter;
      }
    },
    selectCity(city) {
      if (this.isDesktop) {
        this.selectedCity = city;
        this.activeFilter = "";
        this.emitFilterData();
      } else {
        this.currentMobileFilter = "plats";
        this.selectedCity = city;
        this.applyFiltersMobile();
      }
    },
    selectDepartment(department) {
      if (this.isDesktop) {
        this.selectedDepartment = department;
        this.activeFilter = "";
        this.emitFilterData();
      } else {
        this.departments.forEach(depart => {
          depart.isActive = false;
        });
        let chosenDepartment = this.departments.find(d => d.id === department.id);
        chosenDepartment.isActive = true;
        this.selectedDepartment = department;
        this.currentMobileFilter = "departments";
        this.applyFiltersMobile();
      }
    },
    applySelectedCity(city) {
      this.cities.forEach(currentCity => {
        currentCity.isActive = false;
      });

      this.allAreas.forEach(area => {
        if(area && area.isActive) {
          area.isActive = false;
        }
      });

      let selectedCity = this.cities.find(c => c.id === city.id);
      if(selectedCity.areaReferences) {
        selectedCity.areaReferences.forEach(area => {
        area.isActive = false;
      });
      }

      selectedCity.isActive = true;
      this.selectCity(city)
    },

    addallAreasToArray() {
      this.allAreas = this.cities.reduce((accumulator, city) => {
        return accumulator.concat(city.areaReferences);
      }, []);
    },

    applySelectedArea(cityId, area) {
      this.cities.forEach(city => {
        city.isActive = false;
      });

      let selectedCity = this.cities.find(city => city.id === cityId);
      selectedCity.areaReferences.forEach(area => {
        area.isActive = false;
      });

      this.allAreas.forEach(area => {
        if(area && area.isActive) {
          area.isActive = false;
        }
      });

      let selectedArea = selectedCity.areaReferences.find(a => a.id === area.id);
      selectedArea.isActive = true;
      this.selectedCity = area;
      this.activeFilter = "";
      this.currentMobileFilter = "plats";
      this.applyFiltersMobile();
    },
    applyFiltersMobile() {
      this.emitFilterData();
      this.showFilters = false;
    },
    searchContacts() {
      if (this.isDesktop) {
        this.emitFilterData();
      } else {
        this.emitFilterData();
        this.showFilters = false;
      }
    },
    checkScreenSize() {
      this.isDesktop = window.innerWidth >= 768;
    },
    showMobileFilters() {
      this.activeFilter = this.currentMobileFilter;
      this.showFilters = true;
    },
    handleClickOutside() {
      if (this.activeFilter === "plats" && this.isDesktop) {
        this.activeFilter = "";
      } else if (this.activeFilter === "departments" && this.isDesktop) {
        this.activeFilter = "";
      }
    },
    emitFilterData() {
      this.$emit("filter-changed", {
        selectedCity: this.selectedCity.id,
        selectedDepartment: this.selectedDepartment.id,
        searchValue: this.searchValue,
      });
    },
  },

  mounted() {
    if (this.cities.length > 0) {
      let city = this.cities[0];
      this.selectCity(city);
    }

    this.addallAreasToArray();

    if (this.departments.length > 0) {
      let department = this.departments[0];
      this.selectDepartment(department);
    }
  },

  created() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
};
</script>
