<template>
	<div class="c-main-nav" :class="isOpen ? 'c-main-nav--overlay' : ''">
		<div v-click-outside="close">
			<button @click="open" class="c-main-nav__toggle" :style="[isOpen ? {display: 'none'} : {display: 'block'}]"
				:aria-expanded="isOpen ? 'true' : 'false'" aria-controls="main-nav-content" @keydown.esc="close">
				<span class="t-visually-hidden" data-js="nav-text">Öppna meny</span>
				<span class="c-main-nav__nav-burger" aria-hidden="true">
					<span class="c-main-nav__nav-bar"> </span>
					<span class="c-main-nav__nav-bar"> </span>
					<span class="c-main-nav__nav-bar"> </span>
				</span>
				<span class="c-main-nav__nav-cross" aria-hidden="true">
					<span class="c-main-nav__nav-line"> </span>
					<span class="c-main-nav__nav-line"> </span>
				</span>
			</button>
			<transition name="toggle">
				<div id="main-nav-content" class="c-main-nav__content" v-show="isOpen" ref="content">

					<div class="c-main-nav">
						<div class="c-main-nav__sticky-top">
							<div class="c-main-nav__change-lang-top-container">
								<div class="c-main-nav__change-lang-container">
									<div class="c-main-nav__change-lang-block">
										<span class="t-visually-hidden">Byt hemsidans domänspråk</span>
										<span aria-hidden="true" class="c-icon c-icon--xs c-main-nav__lang-icon">
											<svg>
												<use :xlink:href="`#icon-globe`"></use>
											</svg>
										</span>
										<a href="/sv">SV</a> / <a href="https://www.wihlborgs.dk/"
											target="_blank">DK</a>
									</div>
									<div class="c-main-nav__change-lang-block">
										<span class="t-visually-hidden">Byt textspråk</span>
										<span aria-hidden="true" class="c-icon c-icon--xs c-main-nav__lang-icon">
											<svg>
												<use :xlink:href="`#icon-translate`"></use>
											</svg>
										</span>
										<div v-for="(item, index) in languagesList" :key="index">
											<a class="language-link-text" :href="item.Href">{{ item.Text }}</a> <span class="language-link-slash" v-if="index < languagesList.length - 1">/</span>
										</div>
									</div>
								</div>
								<button class="c-main-nav__close" @click="close">
									<span aria-hidden="true" class="c-icon c-icon--xs">
										<svg>
											<use xlink:href="#icon-close"></use>
										</svg>
									</span>
									<span class="t-visually-hidden">Stäng</span>
								</button>
							</div>
						</div>
						<slot />
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import vClickOutside from "v-click-outside";
import {
	disableBodyScroll,
	enableBodyScroll,
	clearAllBodyScrollLocks
} from "body-scroll-lock-upgrade";

export default {
	name: "VMainNav",
	directives: {
		clickOutside: vClickOutside.directive
	},
	props: {
		languages: {
			type: Array,
		},
	},
	data() {
		return {
			isOpen: false,
			languagesList: this.languages,
		};
	},
	beforeDestroy() {
		clearAllBodyScrollLocks();
	},
	methods: {
		open() {
			disableBodyScroll(this.$refs.content);
			this.isOpen = true;

			this.focusFirst();
		},
		close(e) {
			if (e.target.hasAttribute("data-open-popup")) {
				return;
			}
			enableBodyScroll(this.$refs.content);
			this.isOpen = this.isOpen && false;
		},
		focusFirst() {
			const firstFocusable = this.$refs.content.querySelector(
				"a, button, input, textarea, select, details, [tabindex]:not([tabindex='-1'])"
			);
			if (firstFocusable) {
				firstFocusable.focus();
			}
		}
	},
	watch: {
		isOpen() {
			document.querySelector('html').style.overflow = this.isOpen ? 'hidden' : 'auto';
			document.querySelector('.cookieconsent-link').style.display = this.isOpen ? 'none' : 'block';
		}
  	}
};
</script>
