<template>
	<div class="c-mobile-nav__item">
		<a :href="url" class="c-button c-mobile-nav__action">
			<slot />
		</a>
	</div>
</template>

<script>
export default {
	name: "VMobileNavAction",
	props: {
		url: {
			type: String,
			required: true
		}
	}
};
</script>
