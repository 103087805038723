var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.images.length >= _vm.minNumToShow
    ? _c(
        "div",
        {
          ref: "thumbnailWrapper",
          class: `c-image-scroller ${
            _vm.controlsLiveRegion
              ? "c-image-scroller--controls-live-region"
              : ""
          }`,
        },
        [
          _c("div", { staticClass: "c-image-scroller__image-row" }, [
            _vm.scrollStep > 0
              ? _c(
                  "div",
                  {
                    staticClass:
                      "c-image-scroller__thumbnail-nav c-image-scroller__thumbnail-nav--left",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "c-button c-button--round c-hero__button",
                        attrs: { type: "button" },
                        on: { click: _vm.scrollLeft },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "c-icon c-icon--s c-icon--flip-x",
                            attrs: { "aria-hidden": "true" },
                          },
                          [
                            _c("svg", [
                              _c("use", {
                                attrs: { "xlink:href": "#icon-arrow" },
                              }),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "t-visually-hidden" }, [
                          _vm._v(_vm._s(_vm.scrollLeftText)),
                        ]),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "thumbnails",
                staticClass: "c-image-scroller__thumbnails",
                style: `left: -${
                  _vm.thumbnailWidth * _vm.scrollStep * _vm.stepLength
                }%`,
              },
              [
                _vm._l(_vm.images, function (image, index) {
                  return [
                    _c(
                      _vm.controlsLiveRegion ? "button" : "a",
                      {
                        tag: "component",
                        class: `c-image-scroller__thumbnail-button ${
                          _vm.controlsLiveRegion && _vm.currentIndex === index
                            ? "c-image-scroller__thumbnail-button--current"
                            : ""
                        }`,
                        attrs: {
                          type: _vm.controlsLiveRegion ? "button" : undefined,
                          "aria-pressed": _vm.controlsLiveRegion
                            ? _vm.currentIndex === index
                              ? "true"
                              : "false"
                            : undefined,
                          id: image.id,
                          href: _vm.isLinks ? image.href : undefined,
                          tabindex: _vm.imageInView(index) ? "0" : "-1",
                        },
                        on: {
                          click: function ($event) {
                            _vm.controlsLiveRegion
                              ? _vm.selectImage(index, image.id)
                              : undefined
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "c-image-scroller__thumbnail",
                          attrs: { src: image.url, alt: image.text },
                        }),
                      ]
                    ),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _vm.showNavRight
              ? _c(
                  "div",
                  {
                    staticClass:
                      "c-image-scroller__thumbnail-nav c-image-scroller__thumbnail-nav--right",
                  },
                  [
                    _c(
                      "button",
                      {
                        ref: "buttonScrollRight",
                        staticClass: "c-button c-button--round c-hero__button",
                        attrs: { type: "button" },
                        on: { click: _vm.scrollRight },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "c-icon c-icon--s",
                            attrs: { "aria-hidden": "true" },
                          },
                          [
                            _c("svg", [
                              _c("use", {
                                attrs: { "xlink:href": "#icon-arrow" },
                              }),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "t-visually-hidden" }, [
                          _vm._v(_vm._s(_vm.scrollRightText)),
                        ]),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }