var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      ref: "nav",
      staticClass: "c-page-nav",
      class: `
		variant--${_vm.variant}
		${!_vm.condensed ? " t-padding--bottom t-padding--top" : ""}
		${_vm.stuck ? "is-stuck" : ""}
	`,
      style: `height: ${_vm.navHeight}px`,
    },
    [
      _c("div", { staticClass: "c-page-nav__wrapper" }, [
        _c(
          "div",
          { staticClass: "c-page-nav__inner o-width-limiter" },
          [_vm._t("default")],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }