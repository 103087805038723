var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-main-nav__item-container",
      class: { "c-main-nav__item-row-expanded": _vm.level !== 1 },
    },
    [
      _c("div", { staticClass: "c-main-nav__item" }, [
        _c(
          "div",
          {
            staticClass: "c-main-nav__item-row",
            class: {
              "c-main-nav__item-row-border-bottom":
                _vm.expanded && _vm.level === 1,
            },
          },
          [
            _c(
              "a",
              {
                staticClass: "c-main-nav__link",
                style: [
                  _vm.active
                    ? { fontWeight: "500", color: "#5c8fb4" }
                    : { fontWeight: "200" },
                  _vm.expanded ? { fontWeight: "500" } : { fontWeight: "200" },
                  _vm.level === 1
                    ? { padding: "16px 0" }
                    : { padding: "12px 0" },
                ],
                attrs: { href: `${_vm.anchor}` },
              },
              [
                _vm._v(
                  "\n                " + _vm._s(_vm.title) + "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.expandable
              ? _c(
                  "button",
                  {
                    staticClass: "c-main-nav__expand-item-button",
                    on: {
                      click: function ($event) {
                        _vm.expanded = !_vm.expanded
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "t-visually-hidden" }, [
                      _vm._v("Expandera menyrad"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "c-icon c-icon--xs c-main-nav__plus-icon",
                        attrs: { "aria-hidden": "true" },
                      },
                      [
                        !_vm.expanded
                          ? _c("svg", [
                              _c("use", {
                                attrs: { "xlink:href": `#icon-plus` },
                              }),
                            ])
                          : _c("svg", [
                              _c("use", {
                                attrs: { "xlink:href": `#icon-minus` },
                              }),
                            ]),
                      ]
                    ),
                  ]
                )
              : _c("div"),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.expanded ? _vm._t("default") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }