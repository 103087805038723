var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-gallery" },
    [
      _c(
        "figure",
        {
          staticClass: "c-gallery__main-image",
          attrs: {
            id: "gallery",
            "aria-live": _vm.isLive !== "" ? _vm.isLive : false,
            "aria-atomic": _vm.isAtomic !== "" ? _vm.isAtomic : false,
            "aria-labelledby": _vm.currentId,
          },
        },
        [
          _c("img", {
            attrs: { src: _vm.currentImage.url, alt: _vm.currentImage.text },
          }),
        ]
      ),
      _vm._v(" "),
      _c("v-image-scroller", {
        attrs: {
          images: _vm.images,
          "step-length": _vm.stepLength,
          "aria-controls": "gallery",
          "controls-live-region": true,
          "min-num-to-show": "2",
        },
        on: { selectImage: _vm.selectImage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }