var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-main-nav",
      class: _vm.isOpen ? "c-main-nav--overlay" : "",
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.close,
              expression: "close",
            },
          ],
        },
        [
          _c(
            "button",
            {
              staticClass: "c-main-nav__toggle",
              style: [_vm.isOpen ? { display: "none" } : { display: "block" }],
              attrs: {
                "aria-expanded": _vm.isOpen ? "true" : "false",
                "aria-controls": "main-nav-content",
              },
              on: {
                click: _vm.open,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  )
                    return null
                  return _vm.close.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "t-visually-hidden",
                  attrs: { "data-js": "nav-text" },
                },
                [_vm._v("Öppna meny")]
              ),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
            ]
          ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "toggle" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isOpen,
                    expression: "isOpen",
                  },
                ],
                ref: "content",
                staticClass: "c-main-nav__content",
                attrs: { id: "main-nav-content" },
              },
              [
                _c(
                  "div",
                  { staticClass: "c-main-nav" },
                  [
                    _c("div", { staticClass: "c-main-nav__sticky-top" }, [
                      _c(
                        "div",
                        {
                          staticClass: "c-main-nav__change-lang-top-container",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "c-main-nav__change-lang-container",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "c-main-nav__change-lang-block",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "t-visually-hidden" },
                                    [_vm._v("Byt hemsidans domänspråk")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "c-icon c-icon--xs c-main-nav__lang-icon",
                                      attrs: { "aria-hidden": "true" },
                                    },
                                    [
                                      _c("svg", [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href": `#icon-globe`,
                                          },
                                        }),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("a", { attrs: { href: "/sv" } }, [
                                    _vm._v("SV"),
                                  ]),
                                  _vm._v(" / "),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "https://www.wihlborgs.dk/",
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("DK")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "c-main-nav__change-lang-block",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "t-visually-hidden" },
                                    [_vm._v("Byt textspråk")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "c-icon c-icon--xs c-main-nav__lang-icon",
                                      attrs: { "aria-hidden": "true" },
                                    },
                                    [
                                      _c("svg", [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href": `#icon-translate`,
                                          },
                                        }),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.languagesList,
                                    function (item, index) {
                                      return _c("div", { key: index }, [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "language-link-text",
                                            attrs: { href: item.Href },
                                          },
                                          [_vm._v(_vm._s(item.Text))]
                                        ),
                                        _vm._v(" "),
                                        index < _vm.languagesList.length - 1
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "language-link-slash",
                                              },
                                              [_vm._v("/")]
                                            )
                                          : _vm._e(),
                                      ])
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "c-main-nav__close",
                              on: { click: _vm.close },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "c-icon c-icon--xs",
                                  attrs: { "aria-hidden": "true" },
                                },
                                [
                                  _c("svg", [
                                    _c("use", {
                                      attrs: { "xlink:href": "#icon-close" },
                                    }),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "t-visually-hidden" }, [
                                _vm._v("Stäng"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._t("default"),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticClass: "c-main-nav__nav-burger",
        attrs: { "aria-hidden": "true" },
      },
      [
        _c("span", { staticClass: "c-main-nav__nav-bar" }),
        _vm._v(" "),
        _c("span", { staticClass: "c-main-nav__nav-bar" }),
        _vm._v(" "),
        _c("span", { staticClass: "c-main-nav__nav-bar" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticClass: "c-main-nav__nav-cross",
        attrs: { "aria-hidden": "true" },
      },
      [
        _c("span", { staticClass: "c-main-nav__nav-line" }),
        _vm._v(" "),
        _c("span", { staticClass: "c-main-nav__nav-line" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }