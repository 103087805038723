<template>
  <div :id="fullNameId" @keydown.esc="close" class="c-contact-coworker__item"
    :class="contactIsOpen ? 'c-contact-worker--overlay' : ''">

    <div class="c-contact-worker__img-wrapper">
      <a v-if="contact.imageUrl" :href="contact.url"><img class="c-contact-coworker__img" :src="contact.imageUrl"
          :alt="contact.fullName" />
      </a>
      <div class="c-contact-worker__header-wrapper">
        <h4 class="c-contact-coworker__heading">{{ contact.fullName }}</h4>
        <span class="c-contact-coworker__title">{{ contact.titleText }}, {{ contact.cityName }}</span>
      </div>
    </div>
    <div class="c-contact-coworker__info">
      <address>
        <div>
          <div v-click-outside="close">
            <button @click="open" :style="[contactIsOpen ? { display: 'none' } : { display: 'block' }]"
              :aria-expanded="contactIsOpen ? 'true' : 'false'" class="c-contact-worker__toggle" @keydown.esc="close">
              <span class="c-contact-coworker__number">
                <p>Visa kontaktuppgifter</p>
                <span aria-hidden="true" class="c-icon c-icon--xs c-main-nav__search-icon">
                  <span aria-hidden="true" class="c-icon c-icon--xs"><svg>
                      <use xlink:href="#icon-arrow"></use>
                    </svg></span>
                </span>
              </span>
            </button>
            <transition name="toggle">
              <div v-show="contactIsOpen" id="contact-worker-content" class="c-contact-worker__content" ref="content">
                <div class="c-contact-worker__sticky-top">
                  <button class="close-contact" @click="close">
                    <span aria-hidden="true" class="c-icon c-icon--xs">
                      <svg>
                        <use xlink:href="#icon-close"></use>
                      </svg>
                    </span>
                    <span class="t-visually-hidden">Stäng</span>
                  </button>
                </div>

                <div class="contact-worker-card-container">
                  <img class="contact-worker-card__img" :src="contact.imageUrl" :alt="contact.fullName" />

                  <div class="contact-worker-card-background">
                    <div class="coworker-card-contactinformation">
                      <h3 class="coworkeritem_top">{{ contact.fullName }}</h3>
                      <div>
                        <div class="contact-text-row">{{ contact.titleText }}, {{ contact.cityName }}</div>
                        <div class="contact-text-row"><a :href="'tel:' + contact.phone">{{ contact.phone }} </a></div>
                        <div class="contact-text-row"><a :href="'mailto:' + contact.email">{{ contact.email }} </a></div>
                      </div>
                      <div>
                        <div class="contactdepartmentcontainer contactworkerarea contactworkerarea-text-row">
                          <div class="contactworkerarea-text bold-text">Avdelning: </div> {{
                            contact.departments }}
                        </div>
                        <div class="contactworkerarea contactworkerarea-text-row">
                          <div class="contactworkerarea-text bold-text">Ansvarsområden: </div> {{
                            contact.responsibilityText }}
                        </div>
                        <div class="contactworkerarea contact-text-row">
                          <div class="contactworkerarea-text bold-text">Område: </div> {{ contact.cityName }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </address>
    </div>

    <div id="overlay" class="overlay" :class="{ 'show': contactIsOpen }"></div>

  </div>
</template>
<script>

import vClickOutside from "v-click-outside";

export default {
  name: "VContactCoworkerItem",
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contactIsOpen: false,
      windowWidth: window.innerWidth,
      isDesktop: false,
      scrollEnabled: true,
    };
  },
  mounted() {
    const contactModalContainer = this.$el.querySelector("#contact-worker-content");
    contactModalContainer.addEventListener("touchstart", this.handleTouchStart);
    contactModalContainer.addEventListener("touchmove", this.handleTouchMove);
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    const contactModalContainer = this.$el.querySelector("#contact-worker-content");
    contactModalContainer.removeEventListener("touchstart", this.handleTouchStart);
    contactModalContainer.removeEventListener("touchmove", this.handleTouchMove);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    toggleNumber(contact) {
      contact.showNumber = !contact.showNumber;
    },
    toggleEmail(contact) {
      contact.showEmail = !contact.showEmail;
    },
    open() {
      this.toggleScrollEnabled;
      this.contactIsOpen = true;
      this.focusFirst();
    },
    close(e) {
      if (e.target.hasAttribute("data-open-popup")) {
        return;
      }

      this.toggleScrollEnabled;
      this.contactIsOpen = this.contactIsOpen && false;
    },
    focusFirst() {
      const firstFocusable = this.$refs.content.querySelector(
        "a, button, input, textarea, select, details, [tabindex]:not([tabindex='-1'])"
      );
      if (firstFocusable) {
        firstFocusable.focus();
      }
    },
    checkScreenSize() {
      this.isDesktop = window.innerWidth >= 768;
    },
    toggleScrollEnabled() {
      this.scrollEnabled = !this.scrollEnabled;
    },
    handleTouchStart(event) {
      this.lastY = event.touches[0].clientY;
    },
    handleTouchMove(event) {
      const top = event.touches[0].clientY;
      const scrollTop = event.currentTarget.scrollTop;
      const direction = this.lastY - top < 0 ? "up" : "down";

      if (this.scrollEnabled && scrollTop === 0 && direction === "up") {
        event.preventDefault();
      } else if (
        this.scrollEnabled &&
        scrollTop >=
        event.currentTarget.scrollHeight - event.target.offsetHeight &&
        direction === "down"
      ) {
        event.preventDefault();
      }

      this.lastY = top;
    }
  },
  created() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkScreenSize);
  },

  computed: {
    isMobile() {
      return this.windowWidth <= 768;
    },
    fullNameId() {
      return (
        "hcard-" + this.contact.fullName.replace(/\s+/g, "-").toLowerCase()
      );
    },
  },
  watch: {
    contactIsOpen() {
      document.querySelector('html').style.overflow = this.contactIsOpen ? 'hidden' : 'auto';
    }
  }
};
</script>
