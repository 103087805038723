/**
 * Accordion
 */

export default function() {

	if (!document.querySelector('[data-js="accordion"]')) {
		return;
	}

	const accordions = document.querySelectorAll('[data-js="accordion"]');

	accordions.forEach(accordion => {
		let isOpen = false;
		const trigger = accordion.querySelector('[data-accordion="trigger"]');
		const content = accordion.querySelector('[data-accordion="content"]');

		trigger.addEventListener('click', () => {
			isOpen = !isOpen;

			if (isOpen) {
				content.removeAttribute('hidden');
				trigger.setAttribute('aria-expanded', true);
				accordion.setAttribute('aria-expanded', true);
			}
			else {
				content.setAttribute('hidden', true);
				trigger.setAttribute('aria-expanded', false);
				accordion.setAttribute('aria-expanded', false);
			}
		});
	});
}
