var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-filter-dropdown" },
    [
      _c(
        "button",
        {
          staticClass: "c-filter-dropdown__button",
          class: { "c-filter-dropdown__button--active": _vm.dropdownOpen },
          on: {
            click: function ($event) {
              return _vm.results()
            },
          },
        },
        [
          _vm.tag.includes("departments")
            ? _c("div", { staticClass: "c-filter-dropdown__button-tag" }, [
                _vm._v("Välj avdelning"),
              ])
            : _vm.tag.includes("plats")
            ? _c("div", { staticClass: "c-filter-dropdown__button-tag" }, [
                _vm._v("Välj område"),
              ])
            : _c("div", { staticClass: "c-filter-dropdown__button-tag" }, [
                _vm._v("Välj " + _vm._s(_vm.tag)),
              ]),
          _vm._v(" "),
          _c("div", { staticClass: "c-filter-dropdown__button-text" }, [
            _vm._v(_vm._s(_vm.buttonText)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "c-icon c-icon--xs c-filter-dropdown__toggle-icon",
              attrs: { "aria-hidden": "true" },
            },
            [
              !_vm.dropdownOpen
                ? _c("svg", [
                    _c("use", { attrs: { "xlink:href": `#icon-plus` } }),
                  ])
                : _c("svg", [
                    _c("use", { attrs: { "xlink:href": `#icon-minus` } }),
                  ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }