var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-range-slider" }, [
    _c("div", { staticClass: "c-range-slider__values" }, [
      _c("span", { staticClass: "c-range-slider__label" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _vm._v(" "),
      _c("span", { attrs: { id: _vm.id + "-min-value" } }, [
        _vm._v(_vm._s(_vm.min.value) + " kvm"),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-range-slider__values" }, [
      _c("span", { attrs: { id: _vm.id + "-max-value" } }, [
        _vm._v(
          _vm._s(_vm.max.value) +
            " " +
            _vm._s(_vm.max.value === this.adjustedMax ? "+" : "") +
            " kvm"
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-range-slider__control" }, [
      _c("div", { ref: "track", staticClass: "c-range-slider__track" }, [
        _c("div", {
          staticClass: "c-range-slider__fill",
          style:
            "left:" +
            _vm.min.position +
            "%; width:" +
            (_vm.max.position - _vm.min.position) +
            "%;",
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "c-range-slider__thumb-container",
          style:
            "left:" + _vm.min.position + "%; z-index:" + _vm.min.zindex + ";",
          attrs: {
            role: "slider",
            "aria-valuemin": _vm.minValue,
            "aria-valuemax": _vm.maxValue,
            "aria-valuenow": _vm.min.value,
            "aria-controls": _vm.id + "-min-value",
            "aria-orientation": "horizontal",
            tabindex: "0",
          },
          on: {
            mousedown: function ($event) {
              $event.preventDefault()
              return _vm.startSlide("min")
            },
            touchstart: function ($event) {
              return _vm.startSlide("min")
            },
            keydown: function ($event) {
              return _vm.keySlide("min", $event)
            },
            keyup: _vm.stopSlide,
          },
        },
        [_c("div", { staticClass: "c-range-slider__thumb" })]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "c-range-slider__thumb-container",
          style:
            "left:" + _vm.max.position + "%; z-index:" + _vm.max.zindex + ";",
          attrs: {
            role: "slider",
            "aria-valuemin": _vm.minValue,
            "aria-valuemax": _vm.maxValue,
            "aria-valuenow": _vm.max.value,
            "aria-controls": _vm.id + "-max-value",
            "aria-orientation": "horizontal",
            tabindex: "0",
          },
          on: {
            mousedown: function ($event) {
              $event.preventDefault()
              return _vm.startSlide("max")
            },
            touchstart: function ($event) {
              return _vm.startSlide("max")
            },
            keydown: function ($event) {
              return _vm.keySlide("max", $event)
            },
            keyup: _vm.stopSlide,
          },
        },
        [_c("div", { staticClass: "c-range-slider__thumb" })]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }