export default function() {
    var location = document.location.pathname;
    console.log('location', location);

    var cookie = getCookie("CookieConsent");
    var cookiesArray = cookie.split(",");

    var $blocks = document.querySelectorAll(".block");
 
    $blocks.forEach(function(block) {
      var consent = block.querySelector("[data-cookieconsent]");
   
      if(consent != null){
        var $consent = $(consent);
        var data = $consent.attr("data-cookieconsent");
    
        if (data != null && data != "ignore") {
            var html = "";
            var values = data.split(',');
                   
            for (var i = 0; i < values.length; i++) {
                var cookieValue = values[i];
                var exist = $.inArray(cookieValue + ":true", cookiesArray) != -1;
                      
                if (exist)
                  continue;
        
                var className = "cookieconsent-optout-" + cookieValue;

                if(location.indexOf("/sv/") != -1){
                    html += "<div style=\"display:block\" class="+className+">" +
                    "<a href=\"javascript: Cookiebot.renew()\">Acceptera " + cookieValue + " kakor </a>" +
                    "för att se detta innehåll.</div><br>";   
                }
                else{
                    html += "<div style=\"display:block\" class="+className+">" +
                    "<a href=\"javascript: Cookiebot.renew()\">Accept " + cookieValue + " cookies </a>" +
                    "to be able to view this content.</div><br>";   
                }
                        
              }
              $consent.parent().append(html);
         }   
      }    
    });

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
}