<template>
    <div class="c-filter-dropdown">
        <button class="c-filter-dropdown__button" :class="{'c-filter-dropdown__button--active': dropdownOpen}" @click=results()>
            <div v-if="tag.includes('departments')" class="c-filter-dropdown__button-tag">Välj avdelning</div>
            <div v-else-if="tag.includes('plats')" class="c-filter-dropdown__button-tag">Välj område</div>
            <div v-else class="c-filter-dropdown__button-tag">Välj {{ tag }}</div>
            <div class="c-filter-dropdown__button-text">{{ buttonText }}</div>
            <div aria-hidden="true" class="c-icon c-icon--xs c-filter-dropdown__toggle-icon">
                <svg v-if="!dropdownOpen"><use :xlink:href="`#icon-plus`"></use></svg>
                <svg v-else><use :xlink:href="`#icon-minus`"></use></svg>
            </div>
        </button>
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'VFilterDropdown',
    props: {
		tag: {
			type: String,
			required: false
		},
        buttonText: {
            type: String,
            required: true
        },
        dropdownOpen: {
            type: Boolean,
            required: true
        }
	},
    data() {
        return {
        }
    },

    methods: {
        results() {
            this.$emit('populate-filter', [{dropdownOpen: this.dropdownOpen}, {tag: this.tag}])
        }
    }
}
</script>
