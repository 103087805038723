<template>
	<div>
		<v-filter
		:objectsFetched="objectsFetched"
		:objects="filteredObjects"
		:multiSelectFilters="multiSelectFilters"
		@setMultiSelectFilter="handleSelectedFilters"
		@setSelectedPlacesLength="handleSelectedPlaces"
		@setSelectedTypesOfBuildingsLength="handleSelectedTypesOfBuildings"
		@setMinSize="handleMinSize"
		@setMaxSize="handleMaxSize"
		@setSortingOption="handleSortingOption"></v-filter>
		<div class="c-object-listing" style="display: block;">
			<div class="c-object-listing__inner o-width-limiter--full">
				<div class="c-object-listing__item"
					v-for="(object, index) in filteredObjects"
					:key="index">
						<v-object
							:url="object.url"
							:image="object.image"
							:title="object.title"
							:sizeFrom="object.sizeFrom"
							:sizeTo="object.sizeTo"
							:adjustedMax="object.adjustedMax"
							:city="object.city"
							:district="object.district"
							:id="`${object.district.toLowerCase()}-${object.city.toLowerCase()}`"
							:address="object.address"
							:premiseType="object.premiseType"
							:features="object.selectedFeatures"
							:overflowFeaturesCount="object.overflowFeaturesCount">
						</v-object>
				</div>
			</div>
			<div class="c-object-listing__loading" v-if="isLoading">
				<div class="c-loading">
					<span class="t-visually-hidden">Laddar...</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import VFilter from '@/components/filter/VFilter.vue';
import VObject from '@/components/object/VObject.vue';
import VObjectListingDataProvider from '@/components/object-listing/object-listing-data-provider/VObjectListingDataProvider.vue';
import VFiltersWrapper from '@/components/filters-wrapper/VFiltersWrapper.vue';

export default {
	name: 'VObjectListing',
	components: {
		VFilter,
		VObject,
		VObjectListingDataProvider,
		VFiltersWrapper
	},
	props: {
		apiUrl: {
			type: String,
			default: ''
		},
		resetAllText: {
			type: String,
			default: 'Återställ val'
		},
		loadingText: {
			type: String,
			default: 'Laddar objekt'
		}
	},
	data() {
		return {
			objectsFetched: false,
			isLoading: true,
			filterData: null,
			selectedFilters: [],
			selectedPlaces: 0,
			selectedTypesOfBuildings: 0,
			minSize: '',
			maxSize: '',
			filteredObjects: [],
			data: async () => {
				let data
				await axios
				.get(options.apiUrl + '/wihl-api/Premises',
				// .get(options.apiUrl + '/pages/premises__test.json',
					{
						crossDomain: true
					})
				.then(response => {
					if(response && response.data) {
						data = response.data.data;
					}
				});
				return data
			}
		}
	},

	methods: {
		handleSelectedFilters(data) {
			this.selectedFilters = data
			if (this.selectedFilters.length === 0) {
				this.filteredObjects = this.currentObjects
				if(this.minSize !== '') {
					this.filteredObjects = this.filteredObjects.filter(object => object.sizeFrom >= parseInt(this.minSize) || object.sizeTo >= parseInt(this.minSize));
				}
				if(this.maxSize !== '') {
					this.filteredObjects = this.filteredObjects.filter(object => object.sizeTo <= parseInt(this.maxSize) || object.sizeFrom <= parseInt(this.maxSize));
				}
			} else {
				
				this.filteredObjects = []	
				this.currentObjects.forEach(object => {
					if (this.selectedPlaces > 0 && this.selectedTypesOfBuildings > 0) {
						if ((this.selectedFilters.includes(object.district.toLowerCase() + '-' + object.city.toLowerCase()) || (this.selectedFilters.includes(object.city))) && (object.premiseType.some(type => this.selectedFilters.includes(type.toLowerCase())))) {
							this.filteredObjects.push(object)
							if(this.minSize !== '') {
								this.filteredObjects = this.filteredObjects.filter(object => object.sizeFrom >= parseInt(this.minSize) || object.sizeTo >= parseInt(this.minSize));
							}
							if(this.maxSize !== '') {
								this.filteredObjects = this.filteredObjects.filter(object => object.sizeTo <= parseInt(this.maxSize) || object.sizeFrom <= parseInt(this.maxSize));
							}
						}
					} else {
						if (this.selectedFilters.includes(object.district.toLowerCase() + '-' + object.city.toLowerCase()) || (this.selectedFilters.includes(object.city))) {
							this.filteredObjects.push(object)
						}				
						if(object.premiseType.some(type => this.selectedFilters.includes(type.toLowerCase())) ) {
							this.filteredObjects.push(object)
						}
						if(this.minSize !== '') {
							this.filteredObjects = this.filteredObjects.filter(object => object.sizeFrom >= parseInt(this.minSize) || object.sizeTo >= parseInt(this.minSize));
						}
						if(this.maxSize !== '') {
							this.filteredObjects = this.filteredObjects.filter(object => object.sizeTo <= parseInt(this.maxSize) || object.sizeFrom <= parseInt(this.maxSize));
						}
					}
					
				})
			}
		},
		handleSelectedPlaces(length) {
			this.selectedPlaces = length
		},
		handleSelectedTypesOfBuildings(length) {
			this.selectedTypesOfBuildings = length
		},
		handleMinSize(size) {
			this.minSize = size
		},
		handleMaxSize(size) {
			this.maxSize = size
		},
		handleSortingOption(option) {
			if (option.value === 1) {
				this.filteredObjects.sort((a, b) => (a.address > b.address) ? 1 : -1)
			} else if (option.value === 2) {
				this.filteredObjects.sort((a, b) => (a.address < b.address) ? 1 : -1)
			} else if (option.value === 3) {
				this.filteredObjects.sort((a, b) => (a.changedDate < b.changedDate) ? 1 : -1)
			} else if (option.value === 4) {
				this.filteredObjects.sort((a, b) => (a.sizeFrom > b.sizeFrom) ? 1 : -1)
			} else if (option.value === 5) {
				this.filteredObjects.sort((a, b) => (a.sizeTo < b.sizeTo) ? 1 : -1)
			}

		}
	},

	computed: {
		location() {
			return this.data.premisesFilters.filter(filter => {
				filter.name === 'location'
			})
		}
	},

	computed: mapGetters('objects', {
		currentObjects: 'currentObjects',
		multiSelectFilters: 'multiSelectFilters',
		rangeFilters: 'rangeFilters',
		loading: 'loading',
		filtersApplied: 'filtersApplied',
	}),
	render() {
        if (!this.$scopedSlots.default) {
            return;
        }

        const slotScope = {
			objects: this.objects,
			currentObjects: this.currentObjects,
			countObjects: this.currentObjects.length,
			multiSelectFilters: this.multiSelectFilters,
			setMultiSelectFilter: this.setMultiSelectFilter,
			rangeFilters: this.rangeFilters,
			isLoading: this.loading,
			setRangeFilter: this.setRangeFilter,
			resetAllFilters: this.resetAllFilters,
			filtersApplied: this.filtersApplied
		};

        return this.$scopedSlots.default(slotScope);
    },
    created() {
		this.$store.dispatch('objects/fetchObjects', {
			apiUrl: this.apiUrl
		}).then(() => {
			this.filteredObjects = this.currentObjects
			this.filteredObjects.sort((a, b) => (a.changedDate < b.changedDate) ? 1 : -1)
			this.isLoading = false
		}).then(() => {
			this.objectsFetched = true
		})
	},
	mounted() {		
	}
}
</script>
