var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-contact-coworker__item",
      class: _vm.contactIsOpen ? "c-contact-worker--overlay" : "",
      attrs: { id: _vm.fullNameId },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.close.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "c-contact-worker__img-wrapper" }, [
        _vm.contact.imageUrl
          ? _c("a", { attrs: { href: _vm.contact.url } }, [
              _c("img", {
                staticClass: "c-contact-coworker__img",
                attrs: { src: _vm.contact.imageUrl, alt: _vm.contact.fullName },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "c-contact-worker__header-wrapper" }, [
          _c("h4", { staticClass: "c-contact-coworker__heading" }, [
            _vm._v(_vm._s(_vm.contact.fullName)),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "c-contact-coworker__title" }, [
            _vm._v(
              _vm._s(_vm.contact.titleText) +
                ", " +
                _vm._s(_vm.contact.cityName)
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-contact-coworker__info" }, [
        _c("address", [
          _c("div", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.close,
                    expression: "close",
                  },
                ],
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "c-contact-worker__toggle",
                    style: [
                      _vm.contactIsOpen
                        ? { display: "none" }
                        : { display: "block" },
                    ],
                    attrs: {
                      "aria-expanded": _vm.contactIsOpen ? "true" : "false",
                    },
                    on: {
                      click: _vm.open,
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "esc", 27, $event.key, [
                            "Esc",
                            "Escape",
                          ])
                        )
                          return null
                        return _vm.close.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "c-contact-coworker__number" }, [
                      _c("p", [_vm._v("Visa kontaktuppgifter")]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "c-icon c-icon--xs c-main-nav__search-icon",
                          attrs: { "aria-hidden": "true" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "c-icon c-icon--xs",
                              attrs: { "aria-hidden": "true" },
                            },
                            [
                              _c("svg", [
                                _c("use", {
                                  attrs: { "xlink:href": "#icon-arrow" },
                                }),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("transition", { attrs: { name: "toggle" } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.contactIsOpen,
                          expression: "contactIsOpen",
                        },
                      ],
                      ref: "content",
                      staticClass: "c-contact-worker__content",
                      attrs: { id: "contact-worker-content" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "c-contact-worker__sticky-top" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "close-contact",
                              on: { click: _vm.close },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "c-icon c-icon--xs",
                                  attrs: { "aria-hidden": "true" },
                                },
                                [
                                  _c("svg", [
                                    _c("use", {
                                      attrs: { "xlink:href": "#icon-close" },
                                    }),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "t-visually-hidden" }, [
                                _vm._v("Stäng"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "contact-worker-card-container" },
                        [
                          _c("img", {
                            staticClass: "contact-worker-card__img",
                            attrs: {
                              src: _vm.contact.imageUrl,
                              alt: _vm.contact.fullName,
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "contact-worker-card-background" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "coworker-card-contactinformation",
                                },
                                [
                                  _c(
                                    "h3",
                                    { staticClass: "coworkeritem_top" },
                                    [_vm._v(_vm._s(_vm.contact.fullName))]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "contact-text-row" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.contact.titleText) +
                                            ", " +
                                            _vm._s(_vm.contact.cityName)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "contact-text-row" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "tel:" + _vm.contact.phone,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.contact.phone) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "contact-text-row" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "mailto:" + _vm.contact.email,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.contact.email) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "contactdepartmentcontainer contactworkerarea contactworkerarea-text-row",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "contactworkerarea-text bold-text",
                                          },
                                          [_vm._v("Avdelning: ")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.contact.departments) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "contactworkerarea contactworkerarea-text-row",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "contactworkerarea-text bold-text",
                                          },
                                          [_vm._v("Ansvarsområden: ")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.contact.responsibilityText
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "contactworkerarea contact-text-row",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "contactworkerarea-text bold-text",
                                          },
                                          [_vm._v("Område: ")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.contact.cityName) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "overlay",
        class: { show: _vm.contactIsOpen },
        attrs: { id: "overlay" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }