<template>
  <div :class="classList">
    <div class="o-width-limiter c-hero__inner c-contact-hero-wrapper">
      <div class="c-hero__content c-hero__content--align-center">
        <h1 class="c-hero__title">{{ heading }}</h1>
        <div class="c-hero__text">
          <p v-html="preamble"></p>
        </div>
        <div class="c-hero__text" v-if="formHeading">
          <p>{{ formHeading }}</p>
          <a :href="buttonLink" class="c-button c-hero__button--light">
            {{ buttonText }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "VHeroContact",
  props: {
    heading: String,
    preamble: String,
    formHeading: String,
    buttonText: String,
    buttonLink: String,
    classList: String,
  },
};
</script>
