<template> 
    <div class="c-main-nav__item-bottom">
        <a :href="`#${anchor}`" class="c-main-nav__item-bottom-link">
			<span aria-hidden="true" class="c-icon c-icon--m c-main-nav__item-bottom-icon">
				<svg><use :xlink:href="`#icon-${icon}`"></use></svg>
			</span>
			<slot />
		</a>
    </div>
</template>

<script>

export default {
    name: 'VMainNavItemBottom',
    props: {
        icon: {
			type: String
		},
		anchor: {
			type: String,
			required: true
		}
	}
}

</script>