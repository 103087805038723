var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-object" }, [
    _c("a", { attrs: { href: _vm.url } }, [
      _c("span", { staticClass: "t-visually-hidden" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
    ]),
    _vm._v(" "),
    _vm.url && _vm.image
      ? _c("div", { staticClass: "c-object__media" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: _vm.image.imgSrc,
                expression: "image.imgSrc",
              },
            ],
            key: _vm.image.imgSrc,
            staticClass: "c-object__image",
            attrs: { alt: _vm.image.altText },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "c-object__content" }, [
      _c("div", { staticClass: "c-object__head" }, [
        _c("h2", { staticClass: "c-object__title" }, [
          _vm._v(_vm._s(_vm.address)),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "c-object__byline" }, [
          _vm._v(_vm._s(_vm.fixedSize) + " i " + _vm._s(_vm.city)),
        ]),
      ]),
      _vm._v(" "),
      _vm.overflowFeaturesCount > 1
        ? _c(
            "div",
            { staticClass: "c-object__icons" },
            [
              _vm._l(_vm.features, function (feature, index) {
                return _c(
                  "span",
                  { key: index, staticClass: "c-object__icon-wrapper" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "c-icon c-icon--xs c-object__icon",
                        attrs: { "aria-hidden": "true" },
                      },
                      [
                        _c("svg", [
                          _c("use", {
                            attrs: { "xlink:href": `#icon-${feature.icon}` },
                          }),
                        ]),
                      ]
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c("span", { staticClass: "c-object__icon-wrapper" }, [
                _c("span", { staticClass: "c-object__icons-more" }, [
                  _vm._v("+" + _vm._s(_vm.overflowFeaturesCount)),
                ]),
              ]),
            ],
            2
          )
        : _c(
            "div",
            { staticClass: "c-object__icons" },
            _vm._l(_vm.features, function (feature, index) {
              return _c(
                "span",
                { key: index, staticClass: "c-object__icon-wrapper" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "c-icon c-icon--xs c-object__icon",
                      attrs: { "aria-hidden": "true" },
                    },
                    [
                      _c("svg", [
                        _c("use", {
                          attrs: { "xlink:href": `#icon-${feature.icon}` },
                        }),
                      ]),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
      _vm._v(" "),
      _c("div", { staticClass: "c-object__body" }, [
        _c("p", { staticClass: "c-object__text" }, [_vm._v(_vm._s(_vm.title))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }