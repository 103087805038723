<template>
  <div class="offices-container o-width-limiter--narrow">
    <div v-for="(office, index) in officeData" :office="office" :key="index" class="offices-item o-width-limiter--narrow">
        <div v-html="office.text"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "VContactOffices",
  props: {
    offices: {
      type: Array,
    },
  },
  data() {
    return {
      apiUrl: "",
      officeData: this.offices,
      loading: true
    };
  },
};
</script>
