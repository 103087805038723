var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-mobile-nav__item" }, [
    _c(
      "a",
      { staticClass: "c-mobile-nav__link", attrs: { href: `#${_vm.anchor}` } },
      [
        _c(
          "span",
          {
            staticClass: "c-icon c-icon--m c-mobile-nav__icon",
            attrs: { "aria-hidden": "true" },
          },
          [
            _c("svg", [
              _c("use", { attrs: { "xlink:href": `#icon-${_vm.icon}` } }),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }