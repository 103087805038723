<template>
	<div class="c-mobile-nav" v-click-outside="close">
		<transition name="open">
			<nav
				id="mobile-navigation"
				class="c-mobile-nav__nav"
				v-show="isOpen"
			>
				<slot />
			</nav>
		</transition>
		<button
			:aria-expanded="isOpen ? 'true' : 'false'"
			aria-controls="mobile-navigation"
			class="c-button c-mobile-nav__toggle"
			@click="toggle"
		>
			<span
				aria-hidden="true"
				class="c-icon c-icon--m c-mobile-nav__toggle-icon"
			>
				<svg><use :xlink:href="`#icon-grid-view`"></use></svg>
			</span>
			<span class="t-visually-hidden">{{label}}</span>
		</button>
	</div>
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
	name: "VMobileNav",
	directives: {
		clickOutside: vClickOutside.directive
	},
	props: {
		label: {
			type: String,
			required: true,
		}
	},
	data() {
		return {
			isOpen: false
		};
	},
	methods: {
		toggle() {
			this.isOpen = !this.isOpen;
		},
		close() {
			this.isOpen = false;
		}
	}
};
</script>
