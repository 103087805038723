<template>
	<div class="c-page-nav__item">
		<a :href="`#${anchor}`" class="c-page-nav__link">
			<span aria-hidden="true" class="c-icon c-icon--l c-page-nav__icon">
				<svg><use :xlink:href="`#icon-${icon}`"></use></svg>
			</span>
			<slot />
		</a>
	</div>
</template>

<script>
export default {
	name: "VPageNavItem",
	props: {
		icon: {
			type: String
		},
		anchor: {
			type: String,
			required: true
		}
	}
};
</script>
