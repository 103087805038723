/**
 * @file This is the main project file where all other scripts should be initiated.
 *
 * Note that since this file will be appended to the `body` element there is no need
 * to wait for the DOMContentLoaded event before initiating your scripts. In fact, it
 * will not work since the DOMContentLoaded event will have been triggered before this
 * file is loaded. Instead use an iife (1) or, as in the example below, a function
 * that is called when a custom event is triggered.
 *
 * 1. https://en.wikipedia.org/wiki/Immediately-invoked_function_expression
 *
 * Examples
 *
 * Load a self-initiating script that does not expect a DOMContentLoaded event:
 * import './path/to/script.js'
 *
 * Load a script that you need to send parameters to or initiate at a specific time:
 * First import the script:
 * import functionName from './path/to/script.js'
 * Note that the path needs to start with './' and be relative to your code root, which
 * is 'project' by default.
 * Then initiate it:
 * functionName(param);
 *
 * See the included examples for more.
 */

/* Vue */
import Vue from "vue";
import VuexStore from "./js/vue-utils/vuex-store/vuex-store.js";
import VPopup from "@/components/popup/VPopup.vue";
import VGallery from "@/components/gallery/VGallery.vue";
import VImageScroller from "@/components/image-scroller/VImageScroller.vue";
import VObject from "@/components/object/VObject.vue";
import VObjectListing from "@/components/object-listing/object-listing/VObjectListing.vue";
import VObjectListingDataProvider from "@/components/object-listing/object-listing-data-provider/VObjectListingDataProvider.vue";
import VFiltersWrapper from "@/components/filters-wrapper/VFiltersWrapper.vue";
import VMultiSelect from "@/components/forms/multi-select/VMultiSelect.vue";
import VRangeSlider from "@/components/forms/range-slider/VRangeSlider.vue";
import VPageNav from "@/components/navigation/page-nav/VPageNav.vue";
import VPageNavItem from "@/components/navigation/page-nav/VPageNavItem.vue";
import VPageNavAction from "@/components/navigation/page-nav/VPageNavAction.vue";
import VMobileNav from "@/components/navigation/mobile-nav/VMobileNav.vue";
import VMobileNavItem from "@/components/navigation/mobile-nav/VMobileNavItem.vue";
import VMobileNavAction from "@/components/navigation/mobile-nav/VMobileNavAction.vue";
import VueLazyLoad from "vue-lazyload";
import VMainNav from "@/components/navigation/main-nav/VMainNav.vue";
import VMainNavItem from "@/components/navigation/main-nav/VMainNavItem.vue";
import VMainNavItemBottom from "@/components/navigation/main-nav/VMainNavItemBottom.vue";
import VFilterDropdown from "@/components/filter/dropdown/VFilterDropdown.vue";
import VFilter from "@/components/filter/VFilter.vue";
import VContactFilter from "@/components/filter/VContactFilter.vue";
import VContactCoworkerList from "@/components/contact/contact-coworker/VContactCoworkerList.vue";
import VContactCoworkerItem from "@/components/contact/contact-coworker/VContactCoworkerItem.vue";
import VContactForm from "@/components/contact/contact-form/VContactForm.vue";
import VHeroContact from "@/components/hero/VHeroContact.vue";
import VContactOffices from "@/components/contact/contact-offices/VContactOffices.vue";

Vue.use(VueLazyLoad);

new Vue({
  el: "#vue-app",
  components: {
    VObjectListingDataProvider,
    VObjectListing,
    VObject,
    VGallery,
    VImageScroller,
    VFiltersWrapper,
    VMultiSelect,
    VRangeSlider,
    VPopup,
    VPageNav,
    VPageNavItem,
    VPageNavAction,
    VMobileNav,
    VMobileNavItem,
    VMobileNavAction,
    VMainNav,
    VMainNavItem,
    VMainNavItemBottom,
    VFilterDropdown,
    VFilter,
    VContactFilter,
    VContactCoworkerItem,
    VContactCoworkerList,
    VHeroContact,
    VContactForm,
    VContactOffices,
  },
  store: VuexStore,
});

Vue.config.devtools = true;

import header from "./components/header/c-header.js";
import hero from "./components/hero/c-hero.js";
import embed from "./components/embed/c-embed.js";
import accordion from "./components/accordion/c-accordion.js";
import formBlock from "./components/form-block/c-form-block.js";
import mediaGallery from "./components/media-gallery/c-media-gallery.js";
import cookieConsent from "./components/cookie-consent/cookieConsent.js";

header();
hero();
embed();
accordion();
formBlock();
mediaGallery();
cookieConsent();

/* Iframe resizer */
if (document.querySelectorAll(".iframe-resize").length > 0) {
  window.addEventListener("message", (e) => {
    let iframes = document.querySelectorAll(".iframe-resize");
    iframes.forEach((iframe) => {
      if (iframe.contentWindow === e.source) {
        iframe.height = e.data.height + "px";
        iframe.style.height = e.data.height + "px";
      }
    });
  });
}

  document.addEventListener("DOMContentLoaded", function() {
    var iframes = document.querySelectorAll('iframe[data-cookieblock-src]:not([data-cookieconsent])');

    iframes.forEach(function(iframe) {
      var cookieBlockSrc = iframe.getAttribute('data-cookieblock-src');
      if (cookieBlockSrc) {
        iframe.setAttribute('src', cookieBlockSrc);
        iframe.removeAttribute('data-cookieblock-src');
      }
    });
  });
