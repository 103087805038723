/**
 * Hero
 */

export default function() {
	const popupTriggers = document.querySelectorAll('[data-open-popup]');
	if (!popupTriggers) {
		return;
	}

	const popupToggle = document.querySelector('.c-popup__toggle');

	popupTriggers.forEach((trigger) => {
		trigger.addEventListener('click', (e) => {
			e.preventDefault();
			if (popupToggle) {
				popupToggle.click();
			}
		});
	});
}
