import Vue from 'vue';
import axios from 'axios';

const state = {
	objects: [],
	multiSelectFilters: [],
	rangeFilters: [],
	filtersApplied: [],
	loading: false
};

axios.interceptors.request.use(
	function(config) {
		state.loading = true;
		return config;
	}
);
axios.interceptors.response.use(
	function(response) {
		state.loading = false;
		return response;
	},
	function(error) {
		state.loading = false;
		console.log(error);
	}
);

const getters = {
	objects: (state) => { return state.objects; },
	loading: (state) => { return state.loading; },
	filtersApplied: (state) => {

		let filters = [];

		state.multiSelectFilters.filter(item => {
			let filter = {
				name: item.name,
				applied: false
			};

			filters.push(filter);
		});

		state.rangeFilters.filter(item => {
			let filter = {
				name: item.name,
				applied: false
			};

			filters.push(filter);
		});

		state.filtersApplied = filters;

		return state.filtersApplied;
	},
	multiSelectFilters: (state) => { return state.multiSelectFilters; },
	rangeFilters: (state) => { return state.rangeFilters; },
	currentObjects: (state, getters) => {
		let objects = getters.objects;
		let location = state.filtersApplied.find(({name}) => name === 'location');
		let premiseType = state.filtersApplied.find(({name}) => name === 'premisetype');
		let area = state.filtersApplied.find(({name}) => name === 'area');

		if (location !== undefined && premiseType !== undefined && area !== undefined) {
			if (location.applied) {
				state.multiSelectFilters.map(filter => {
					let chosenOptions = filter.items.filter(i => i.checked).map(option => option.value);

					if (filter.name === 'location') {
						objects = objects.filter(object => chosenOptions.includes(`${object.district.toLowerCase()}-${object.city.toLowerCase()}`));
					}
				});
			}

			if (premiseType.applied) {
				state.multiSelectFilters.map(filter => {
					let chosenOptions = filter.items.filter(i => i.checked).map(option => option.display);

					if (filter.name === 'premisetype') {
						objects = objects.filter(object => chosenOptions.some(option => object.premiseType.includes(option)));
					}
				});
			}

			if (area.applied) {
				state.rangeFilters.map(filter => {
					objects = objects.filter(object => {

						let A = object.sizeFrom;
						let B = object.sizeTo;
						let x = filter.selectedMin;
						let y = filter.selectedMax;

						if ((A >= x && A <= y) || (A < x && (B >= x && B <= y)) || (B >= x && B <= y) || (B > y && (A >= x && A <= y)) || (A <= x && B >= y)) { // TODO: Verifiera
							return true;
						}
					});
				});
			}
		}

		let currentObjects = objects;
		if (!objects || objects.length === 0) return [];
		return currentObjects;
	},
};

const actions = {
	fetchObjects({dispatch}, options) {
		return new Promise((resolve) => {
			axios
				.get(options.apiUrl + '/wihl-api/Premises',
				// .get(options.apiUrl + '/pages/premises__test.json',
					{
						crossDomain: true
					})
				.then(response => {
					if(response && response.data) {
						let data = response.data.data;

						dispatch('setObjects', {objects: data.premises});
						dispatch('setupFilters', data.premisesFilters);
						this.state.loading = false;
						resolve();
					}
				});
		});
	},
	setObjects({commit}, {objects}) {

		objects = objects.map(object => {
			return object;
		});

		commit('setObjects', objects);
	},
	setMultiSelectFilter ({commit}, filter) {
		commit('setMultiSelectFilter', filter);
	},
	setRangeFilter({commit}, filter) {
		commit('setRangeFilter', filter);
	},
	resetAllFilters({commit}) {
		commit('resetAllFilters');
	},
	setupFilters({commit}, premisesFilters) {
		let allMultiSelectRawOptions = premisesFilters.filter(filter => filter.items);

		if (allMultiSelectRawOptions) {
			allMultiSelectRawOptions.map(rawOptions => {
				if (rawOptions.items) {

					let multiSelectFilter = {};
					multiSelectFilter.displayName = rawOptions.displayName;
					multiSelectFilter.name = rawOptions.name;

					if (multiSelectFilter.name === 'location') {
						let x = [];

						rawOptions.items.forEach((item) => {
							let groupLabel = {
								groupLabel: true,
								display: item.name,
								value: item.name,
								checked: false
							};
							x.push(groupLabel);

							item.districts.map(district => {
								let districtItem = {
									display: district.name,
									value: `${district.name.toLowerCase()}-${item.name.toLowerCase()}`,
									checked: false,
									group: item.name
								};

								x.push(districtItem);
							});
						});
						multiSelectFilter.items = x;
					}
					else {
						multiSelectFilter.items = rawOptions.items.map(item => ({
							display: item,
							value: item.toLowerCase(),
							checked: false
						}));
					}

					// multiSelectFilter.items.unshift({
					// 	toggleAll: true,
					// 	display: 'Alla', /* Specifik sträng från BE? */
					// 	checked: false
					// });

					commit('addMultiSelectFilter', multiSelectFilter);
				}
			});
		}
		let allRangeOptions = premisesFilters.filter(filter => filter.maxValue);

		if (allRangeOptions) {
			allRangeOptions.map(range => {
				range.selectedMin = range.minValue;
				range.selectedMax = range.adjustedMax;

				commit('addRangeFilter', range);
			});
		}
	},
};

const mutations = {
	setObjects(state, objects) {
		Vue.set(state, 'objects', objects);
	},
	addMultiSelectFilter(state, filter) {
		state.multiSelectFilters.push(filter);
	},
	addRangeFilter(state, filter) {
		state.rangeFilters.push(filter);
	},
	setMultiSelectFilter(state, filter) {
		let filterToUpdate = state.multiSelectFilters.find(msf => msf.name == filter.id);

		let filterStatus = state.filtersApplied;

		filterStatus.filter(filter => {
			if (filter.name === filterToUpdate.name) {
				filter.applied = true;
			}
		});

		if (filterToUpdate) {
			Vue.set(filterToUpdate, 'items', filter.options);
			Vue.set(state, 'filtersApplied', filterStatus);
		}
	},
	setRangeFilter(state, filter) {
		let filterToUpdate = state.rangeFilters.find(rf => rf.name === filter.name);

		let filterStatus = state.filtersApplied;

		filterStatus.filter(filter => {
			if (filter.name === filterToUpdate.name) {
				filter.applied = true;
			}
		});

		if (filterToUpdate) {
			Vue.set(filterToUpdate, 'selectedMin', filter.selectedMin);
			Vue.set(filterToUpdate, 'selectedMax', filter.selectedMax);
			Vue.set(state, 'filtersApplied', filterStatus);
		}
	},
	setAreaRange(state, range) {
		Vue.set(state, 'areaRange', range);
	},
	resetAllFilters(state) {
		// Reset URL
		let url = window.location.toString();
		url = url.substring(0, url.indexOf('?'));
		window.history.pushState('', '', url);

		state.multiSelectFilters.map(filter => filter.items.map(item => {
			Vue.set(item, 'checked', false);
		}));
		state.rangeFilters.map(filter => {
			filter.selectedMin = filter.minValue;
			filter.selectedMax = filter.adjustedMax;
		});

		state.filtersApplied.map(filter => {
			filter.applied = false;
		});
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
