<template>
	<div class="c-range-slider">
		<div class="c-range-slider__values">
			<span class="c-range-slider__label">{{label}}</span>
			<span :id="id + '-min-value'">{{min.value}} kvm</span>
		</div>
		<div class="c-range-slider__values">
			<span :id="id + '-max-value'">{{max.value}} {{max.value === this.adjustedMax ? '+' : ''}} kvm</span>
		</div>
		<div class="c-range-slider__control">
			<div class="c-range-slider__track" ref="track">
				<div class="c-range-slider__fill" :style="'left:' + min.position + '%; width:' + (max.position-min.position) + '%;'"></div>
			</div>
			<div class="c-range-slider__thumb-container"
				role="slider"
				:style="'left:' + min.position +'%; z-index:' + min.zindex + ';'"
				:aria-valuemin="minValue"
				:aria-valuemax="maxValue"
				:aria-valuenow="min.value"
				:aria-controls="id + '-min-value'"
				@mousedown.prevent="startSlide('min')"
				@touchstart="startSlide('min')"
				@keydown="keySlide('min', $event)"
				@keyup="stopSlide"
				aria-orientation="horizontal"
				tabindex="0">
				<div class="c-range-slider__thumb"></div>
			</div>
			<div class="c-range-slider__thumb-container"
				role="slider"
				:style="'left:' + max.position +'%; z-index:' + max.zindex + ';'"
				:aria-valuemin="minValue"
				:aria-valuemax="maxValue"
				:aria-valuenow="max.value"
				:aria-controls="id + '-max-value'"
				@mousedown.prevent="startSlide('max')"
				@touchstart="startSlide('max')"
				@keydown="keySlide('max', $event)"
				@keyup="stopSlide"
				aria-orientation="horizontal"
				tabindex="0">
				<div class="c-range-slider__thumb"></div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
    name: 'VRangeSlider',
	props: {
		label: {
			type: String,
			default: null
		},
		maxValue: {
			type: Number
		},
		minValue: {
			type: Number
		},
		adjustedMax: {
			type: Number
		},
		step: {
			type: Number
		},
		id: {
			type: String
		},
		selectedMin: {
			type: Number
		},
		selectedMax: {
			type: Number
		}
	},
	watch: {
		selectedMin: function (newVal, oldVal) {
			if (newVal === this.minValue) {
				this.min.position = 0;
				this.min.value = newVal;
			}
		},
		selectedMax: function (newVal, oldVal) {
			if (newVal === this.adjustedMax) {
				this.max.position = 100;
				this.max.value = newVal;
			}
		},
	},
	data() {
		return {
			min: {
				currentPixel: 0,
				maxPixel: 0,
				sliding: false,
				key: false,
				position: 0,
				value: this.minValue,
				zindex: 0
			},
			max: {
				currentPixel: 0,
				maxPixel: 0,
				sliding: false,
				key: false,
				position: 100,
				value: this.adjustedMax,
				zindex: 1
			},
			range: {
				max: this.adjustedMax,
				min: this.minValue
			},
			maxRightPixels: 0,
			maxLeftPixels: 0,
			keyStep: 0,
			trackPixels: 0
		}
	},
	methods: {
		startSlide(type) {
			if (type === 'max') {
				this.max.sliding = true;
				this.max.zindex = 1;
				this.min.zindex = 0;
			} else {
				this.min.sliding = true;
				this.max.zindex = 0;
				this.min.zindex = 1;
			}
		},
		keySlide(type, event) {
			if (type === 'max') {
				this.max.key = true;
				this.max.zindex = 1;
				this.min.zindex = 0;
			} else {
				this.min.key = true;
				this.max.zindex = 0;
				this.min.zindex = 1;
			}
			this.slide(event);
		},
		slide(event) {
			if (this.max.sliding || this.min.sliding) { /* Mouse or touch movement */
				let position;
				if (event.type === 'mousemove') {
					event.preventDefault();
					position = event.clientX;
				} else if (event.type === 'touchmove') {
					position = event.touches[0].clientX;
				}

				if (this.max.sliding) {
					this.max.currentPixel = position;
				} else {
					this.min.currentPixel = position;
				}
				this.executeSlide();
			} else if (this.max.key ||this.min.key) { /* Arrow key movement */
				if (event.keyCode === 39) { /* Arrow right */
					event.preventDefault();
					if (this.max.key) {
						this.max.currentPixel = this.max.currentPixel + this.keyStep;
					} else {
						this.min.currentPixel = this.min.currentPixel + this.keyStep;
					}
				} else if (event.keyCode === 37) { /* Arrow left */
					event.preventDefault();
					if (this.max.key) {
						this.max.currentPixel = this.max.currentPixel - this.keyStep;
					} else {
						this.min.currentPixel = this.min.currentPixel - this.keyStep;
					}
				}
				this.executeSlide();
			}
		},
		executeSlide() {
			/* If handle is beyond track */
			this.checkBeyondTrack();

			/* Prevent current handle to go lower or higher than the other */
			this.checkRelativePosition();

			/* Calculate position and value */
			this.calculatePosAndValue();

			/* If stepped value is lower or higher than min/max value */
			this.adjustSteppedValue()
		},
		stopSlide() {
			if (this.min.sliding || this.max.sliding) {
				this.max.sliding = false;
				this.min.sliding = false;

				/* Only emit if min or max value changes */
				if (!(this.max.value === this.range.max) || !(this.min.value === this.range.min)) {
					this.range.max = this.max.value;
					this.range.min = this.min.value;
					this.setQueryParams();

					let maxValue = this.max.value === this.adjustedMax ? this.maxValue : this.range.max;

					this.$emit('rangeSelected', { name: this.id, selectedMin: this.range.min, selectedMax: maxValue });
				}
			} else if (this.min.key || this.max.key) {
				this.max.key = false;
				this.min.key = false;

				/* Only emit if min or max value changes */
				if (!(this.max.value === this.range.max) || !(this.min.value === this.range.min)) {
					this.range.max = this.max.value;
					this.range.min = this.min.value;
					this.setQueryParams();

					let maxValue = this.max.value === this.adjustedMax ? this.maxValue : this.range.max;

					this.$emit('rangeSelected', { name: this.id, selectedMin: this.range.min, selectedMax: maxValue });
				}
			}
		},
		checkBeyondTrack() {
			if (this.max.sliding || this.max.key) {
				if (this.max.currentPixel >= this.maxRightPixels) {
					this.max.currentPixel = this.maxRightPixels;
					this.max.position = 100;
					this.max.value = this.adjustedMax;
				} else if (this.max.currentPixel <= this.maxLeftPixels) {
					this.max.currentPixel = this.maxLeftPixels;
					this.max.position = 0;
					this.max.value = this.minValue;
				}
			} else if (this.min.sliding || this.min.key) {
				if (this.min.currentPixel >= this.maxRightPixels) {
					this.min.currentPixel = this.maxRightPixels;
					this.min.position = 0;
					this.min.value = this.minValue;
				} else if (this.min.currentPixel <= this.maxLeftPixels) {
					this.min.currentPixel = this.maxLeftPixels;
					this.min.position = 100;
					this.min.value = this.adjustedMax;
				}
			}
		},
		checkRelativePosition() {
			if (this.max.sliding || this.max.key) {
				if (this.max.currentPixel <= this.min.currentPixel) {
					this.max.currentPixel = this.min.currentPixel;
					this.max.position = this.min.position;
					this.max.value = this.min.value;
				}
			} else if (this.min.sliding || this.min.key) {
				if (this.min.currentPixel >= this.max.currentPixel) {
					this.min.currentPixel = this.max.currentPixel;
					this.min.position = this.max.position;
					this.min.value = this.max.value;
				}
			}
		},
		calculatePosAndValue() {
			if (this.max.sliding || this.max.key) {
				/* Calculate percentage */
				let percent = 1-((this.maxRightPixels-this.max.currentPixel)/this.trackPixels);

				/* Set handle position */
				this.max.position = Math.round(percent*100);

				/* Set value */
				let unstepped = Math.round(percent*(this.adjustedMax-this.minValue)+this.minValue);
				this.max.value = Math.round(unstepped/this.step)*this.step;
			} else if (this.min.sliding || this.min.key) {
				/* Calculate percentage */
				let percent = (this.min.currentPixel-this.maxLeftPixels)/this.trackPixels;

				/* Set handle position */
				this.min.position = Math.round(percent*100);

				/* Set value */
				let unstepped = Math.round(percent*(this.adjustedMax-this.minValue)+this.minValue);
				this.min.value = Math.round(unstepped/this.step)*this.step;
			}
		},
		adjustSteppedValue() {
			if (this.max.sliding || this.max.key) {
				if (this.max.value <= this.minValue) {
					this.max.value = this.minValue;
				} else if (this.max.value >= this.adjustedMax) {
					this.max.value = this.adjustedMax;
				}
			} else if (this.min.sliding || this.min.key) {
				if (this.min.value <= this.minValue) {
					this.min.value = this.minValue;
				} else if (this.min.value >= this.adjustedMax) {
					this.min.value = this.adjustedMax;
				}
			}
		},
		setQueryParams() {
			let url = window.location.toString();
			let newUrl = this.replaceUrlParam(url, this.id, `${this.range.min}%2C${this.range.max}`);
			newUrl = new URL(newUrl);

			window.history.pushState('', '', newUrl.search);
		},
		replaceUrlParam(url, name, value) {
			if (value == null) {
				value = '';
			}
			let pattern = new RegExp('\\b('+name+'=).*?(&|#|$)');
			if (url.search(pattern)>=0) {
				return url.replace(pattern,'$1' + value + '$2');
			}
			url = url.replace(/[?#]$/,'');
			return url + (url.indexOf('?')>0 ? '&' : '?') + name + '=' + value;
		},
		getQueryParamsFromURL (name) {
			name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
			let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
			let results = regex.exec(location.search);
			return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
		},
		setFilterFromParams (values) {
			if (parseInt(values[0]) <= 0 || parseInt(values[0]) > parseInt(values[1])) {
				this.range.min = 0;
			} else {
				this.range.min = parseInt(values[0]);
			}
			if (parseInt(values[1]) > this.adjustedMax || parseInt(values[1]) < parseInt(values[0])) {
				this.range.max = this.adjustedMax;
			} else {
				this.range.max = parseInt(values[1]);
			}
			this.range.max = parseInt(values[1]) > this.adjustedMax ? this.adjustedMax : parseInt(values[1]);
			let min = (this.range.min / this.adjustedMax);
			let max = (this.range.max / this.adjustedMax);
			this.min.position = Math.round(min*100);
			this.max.position = Math.round(max*100);
			this.min.value = this.range.min;
			this.max.value = this.range.max;

			let maxValue = this.max.value === this.adjustedMax ? this.maxValue : this.range.max;

			this.$emit('rangeSelected', { name: this.id, selectedMin: this.range.min, selectedMax: maxValue });
		}
	},
	mounted() {
		let trackPos = this.$refs.track.getBoundingClientRect();
		this.maxLeftPixels = trackPos.left;
		this.maxRightPixels = trackPos.right;
		this.min.currentPixel = trackPos.left;
		this.max.currentPixel = trackPos.right;
		this.trackPixels = this.maxRightPixels - this.maxLeftPixels;
		this.keyStep = (this.step/(this.adjustedMax - this.minValue))*this.trackPixels;

		/* Mouse */
		window.addEventListener('mouseup', this.stopSlide);
		window.addEventListener('mousemove', this.slide);

		/* Touch */
		window.addEventListener('touchend', this.stopSlide);
		window.addEventListener('touchmove', this.slide);
	},
	beforeDestroy() {
		window.removeEventListener('mouseup', this.stopSlide);
		window.removeEventListener('mousemove', this.slide);
		window.removeEventListener('touchend', this.stopSlide);
		window.removeEventListener('touchmove', this.slide);
	},
	created() {
		let queryParams = this.getQueryParamsFromURL(this.id);
		queryParams = decodeURI(queryParams);

		if (queryParams) {
			let values = queryParams.split(",");
			this.setFilterFromParams(values);
		}
	}
}
</script>
