/**
 * Video player
 */

export default function () {

	if (!document.querySelector('.c-embed')) {
		return;
	}

	const embeds = document.querySelectorAll('.c-embed');

	embeds.forEach(embed => {
		let target = embed.querySelector('[data-target]');
		let playButton = embed.querySelector('[data-play]');

		playButton.addEventListener('click', () => { createIframe(target); });
	});

	function createIframe(target) {
		let srcUrl = target.dataset.src;
		let attributes = target.dataset.attr.split(' ');

		let iframe = document.createElement('iframe');

		iframe.src= srcUrl;

		attributes.forEach(attr => {
			iframe.setAttribute(attr.split('=')[0], attr.split('=')[1]);
		});

		target.innerHTML = '';
		target.appendChild(iframe);
	}
}
