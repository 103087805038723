<template>
	<nav
		class="c-page-nav"
		:class="`
			variant--${variant}
			${!condensed ? ' t-padding--bottom t-padding--top' : ''}
			${stuck ? 'is-stuck' : ''}
		`"
		ref="nav"
		:style="`height: ${navHeight}px`"
	>
		<div class="c-page-nav__wrapper">
			<div class="c-page-nav__inner o-width-limiter">
				<slot />
			</div>
		</div>
	</nav>
</template>

<script>
import throttle from "lodash.throttle";
import { mediaQueries } from "../../../js/utilities/constants.js"

export default {
	name: "VPageNav",
	props: {
		variant: {
			type: String,
			default: "tertiary"
		},
		condensed: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			stuck: false,
			navHeight: null
		};
	},
	created() {
		if (window.innerWidth > mediaQueries.sm) {
			window.addEventListener("scroll", throttle(this.handleScroll, 200));
		}
	},
	mounted() {
		this.setHeight();
	},
	destroyed() {
		window.removeEventListener("scroll", this.handleScroll);
	},
	methods: {
		handleScroll() {
			let posTop = this.$refs.nav.getBoundingClientRect().top;
			this.stuck = posTop < -(this.navHeight / 2) ? true : false;
		},
		setHeight() {
			this.navHeight = this.$refs.nav.offsetHeight;
		}
	}
};
</script>
