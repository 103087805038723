var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.close,
          expression: "close",
        },
      ],
      staticClass: "c-mobile-nav",
    },
    [
      _c("transition", { attrs: { name: "open" } }, [
        _c(
          "nav",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOpen,
                expression: "isOpen",
              },
            ],
            staticClass: "c-mobile-nav__nav",
            attrs: { id: "mobile-navigation" },
          },
          [_vm._t("default")],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "c-button c-mobile-nav__toggle",
          attrs: {
            "aria-expanded": _vm.isOpen ? "true" : "false",
            "aria-controls": "mobile-navigation",
          },
          on: { click: _vm.toggle },
        },
        [
          _c(
            "span",
            {
              staticClass: "c-icon c-icon--m c-mobile-nav__toggle-icon",
              attrs: { "aria-hidden": "true" },
            },
            [
              _c("svg", [
                _c("use", { attrs: { "xlink:href": `#icon-grid-view` } }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "t-visually-hidden" }, [
            _vm._v(_vm._s(_vm.label)),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }