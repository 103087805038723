var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("VContactFilter", {
        attrs: { cities: _vm.cities, departments: _vm.departments },
        on: { "filter-changed": _vm.handleFilterChange },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "c-contactworkerlist-container" }, [
        _c(
          "section",
          { staticClass: "c-contact-worker__hits-heading o-width-limiter" },
          [
            _c("div", { staticClass: "c-contact-numberofcoworkers" }, [
              _c("span", { staticClass: "c-filter__hits-number" }, [
                _vm._v(_vm._s(_vm.paginationObject.total)),
              ]),
              _vm._v("\n        resultat\n      "),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "c-contact-worker__list o-width-limiter" },
          _vm._l(_vm.contactWorkers, function (contact, index) {
            return _c("VContactCoworkerItem", {
              key: index,
              attrs: { contact: contact },
            })
          }),
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "c-contact-worker__pagination-wrapper" }, [
          _c("p", { staticClass: "c-contact-worker__pagination-heading" }, [
            _vm._v("\n        Visar\n        "),
            _c("span", { staticClass: "c-contact-worker__display-items" }, [
              _vm._v(_vm._s(_vm.paginationObject.display)),
            ]),
            _vm._v("\n        av\n        "),
            _c("span", { staticClass: "c-contact-worker__total-items" }, [
              _vm._v(_vm._s(_vm.paginationObject.total)),
            ]),
            _vm._v("\n        medarbetare\n      "),
          ]),
          _vm._v(" "),
          _vm.paginationObject.display < _vm.paginationObject.total
            ? _c(
                "a",
                {
                  staticClass: "c-button c-hero__button--light",
                  on: { click: _vm.loadMore },
                },
                [_vm._v("\n        Se fler medarbetare\n      ")]
              )
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }