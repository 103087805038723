<template>
<div class="c-filters-wrapper">
	<div class="c-filters-wrapper__inner o-width-limiter--full">
		<template v-for="filter in multiSelectFilters">
			<v-multi-select v-if="filter && filter.items.length > 0" :label="filter.displayName" :id="filter.name" :options="filter.items" @optionSelected="setMultiSelectFilter"></v-multi-select>
		</template>
		<template v-for="filter in rangeFilters">
			<v-range-slider :label="'Kvadratmeter'" :id="filter.name" :min-value="filter.minValue" :max-value="filter.maxValue" :adjusted-max="filter.adjustedMax" :selected-min="filter.selectedMin" :selected-max="filter.selectedMax" :step="1" @rangeSelected="setRangeFilter"></v-range-slider>
		</template>
		<a href="#" class="c-filters-wrapper__reset" :style="`visibility: ${(filtersApplied) ? 'visible' : 'hidden'}`" @click.prevent="resetAllFilters">{{resetAllText}}</a>
	</div>
</div>
</template>

<script>
import VMultiSelect from '@/components/forms/multi-select/VMultiSelect.vue';
import VRangeSlider from '@/components/forms/range-slider/VRangeSlider.vue';
export default {
    name: 'VFiltersWrapper',
    components: {
        VMultiSelect, VRangeSlider
    },
    props: {
        objects: {
            type: Array,
            default: () => []
        },
		multiSelectFilters: {
			type: Array,
			default: () => []
		},
		rangeFilters: {
			type: Array,
			default: () => []
		},
		filtersApplied: {
			type: Array,
			default: () => []
		},
		resetAllText: {
			type: String,
			default: 'Återställ val'
		},
    },
	methods: {
		setMultiSelectFilter(filter) {
			this.$emit('setMultiSelectFilter', filter);
		},
		setRangeFilter(filter) {
			this.$emit('setRangeFilter', filter);
		},
		resetAllFilters() {
			this.$emit('resetAllFilters');
		},
    },
}
</script>
