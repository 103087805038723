<template>
	<div class="c-multi-select" :id="id" :class="{'is-open': isOpen}">
		<button
			@click="toggleSelect()"
			@keydown="triggerKeyPress($event)"
			ref="trigger"
			class="c-multi-select__trigger"
			aria-haspopup="listbox"
			aria-expanded="false">
			<span class="c-multi-select__container">
				<span class="c-multi-select__label">{{label}}</span>
				<span v-if="numSelectedItems !== 0" class="c-multi-select__active">{{numSelectedItems}} {{countLabel}}</span>
			</span>
			<span aria-hidden="true" class="c-icon c-multi-select__icon">
				<svg>
					<use xlink:href="#icon-select-arrow"></use>
				</svg>
			</span>
		</button>
		<ul
			class="c-multi-select__list"
			@focusout="toggleSelect()"
			@keydown="listKeyPress($event)"
			:aria-activedescendant="id + '-option-' + selectedIndex"
			ref="list"
			role="listbox"
			tabindex="-1">
			<li class="c-multi-select__option"
				v-for="(option, index) in options"
				:class="[(index === selectedIndex ? 'is-active' : ''), (option.groupLabel ? 'c-multi-select__group-label' : '')]"
				:key="index"
				:aria-selected="option.checked ? 'true' : 'false'"
				:aria-labelledby="id + '-label-' + index"
				:id="id + '-option-' + index"
				role="option">
					<input type="checkbox"
						:id="id + '-checkbox-' + index"
						:value="option.value"
						:checked="option.checked ? true : false"
						class="c-custom-checkbox__input">
					<label class="c-custom-checkbox__label c-multi-select__checkbox-label"
						@click.prevent="selectItem(index, option.value)"
						:class="{'is-active': option.checked}"
						:for="id + '-checkbox-' + index"
						:id="id + '-label-' + index">
						{{option.display}}
					</label>
			</li>
		</ul>
	</div>
</template>

<script>

export default {
	name: 'VMultiSelect',
	props: {
		label: {
			type: String,
			default: null
		},
		options: {
			type: Array,
			default: () => []
		},
		id: {
			type: String
		}
	},
	data() {
		return {
			isOpen: false,
			readyToOpen: true,
			selectedIndex: 0,
			countLabel: 'valda',
			scrollPos: 0,
			searchParams: {
				q:null,
				value: ''
			}
		}
	},
	computed: {
        numSelectedItems() {
            return this.options.filter((item) => {return item.checked}).length;
        },
		hasToggleAll() {
			return (this.options.filter((item) => {return item.toggleAll}).length === 1);
		},
		hasGroups() {
			return (this.options.filter((item) => {return item.groupLabel}).length > 0);
		},
		maxIndex() {
			return this.options.length - 1;
		}
    },
	methods: {
		/**
		 * Handle select list toggle
		 */
		toggleSelect() {
			if (this.isOpen) {
				this.isOpen = false;
				this.$refs.trigger.setAttribute('aria-expanded', 'false');
				this.$nextTick(() => {
					this.$refs.trigger.focus();
				});

				this.emitOptions();

				this.setQueryParams();

				this.readyToOpen = false;
				setTimeout(()=>{ this.readyToOpen = true; }, 200);
			} else if (!this.isOpen) {
				if (this.readyToOpen) {
					this.isOpen = true;
					this.$refs.trigger.setAttribute('aria-expanded', 'true');
					this.$nextTick(() => {
						this.$refs.list.focus();
					});
				}
			}
		},
		/**
		 * Handle option click
		 */
		selectItem(index) {
			let option = this.options[index];

			if (option.toggleAll) {
				/* Toggle all */
				if (this.numSelectedItems === this.options.length) {
					this.options.forEach((item) => {item.checked = false});
				} else if (this.numSelectedItems === 0) {
					this.options.forEach((item) => {item.checked = true});
				} else if (!option.checked) {
					this.options.forEach((item) => {item.checked = true});
				}
			}
			else if (option.groupLabel) {
				if (option.checked) {
					option.checked = false;

					this.options.forEach((item) => {
						if (item.group === option.value) {
							item.checked = false;
						}
					});
				} else {
					option.checked = true;

					this.options.forEach((item) => {
						if (item.group === option.value) {
							item.checked = true;
						}
					});
				}
			} else {
				/* Check or uncheck */
				if (option.checked) {
					if (this.hasToggleAll) {
						this.options[0].checked = false;
					} else if (this.hasGroups) {
						this.options.forEach((item) => {
							if (item.city === option.group) {
								item.checked = false;
							}
						});
					};

					option.checked = false;
				} else {
					option.checked = true;
				}
			}

			/* Count selected items and set label */
			//this.numSelectedItems = this.options.filter((item) => {return item.checked}).length;
			if (this.numSelectedItems === 1) {
				this.countLabel = 'vald';
			} else {
				this.countLabel = 'valda';
			}

			this.emitOptions();
		},
		/**
		 * Handle trigger keypress
		 */
		triggerKeyPress(event) {
			switch (event.keyCode) {
				case 40: /* Arrow down */
				case 27: /* Escape */
				case 32: /* Space */
					event.preventDefault();
					this.toggleSelect();
					break;
				default:
					break;
			}
		},
		/**
		 * Handle list keypress
		 */
		listKeyPress(event) {
			switch (event.keyCode) {
				case 40: /* Arrow down */
					event.preventDefault();
					if (this.selectedIndex !== this.maxIndex) {
						this.selectedIndex = this.selectedIndex + 1;
						this.scroll('down');
					}
					break;
				case 38: /* Arrow up */
					event.preventDefault();
					if (this.selectedIndex !== 0) {
						this.selectedIndex = this.selectedIndex - 1;
						this.scroll('up');
					}
					break;
				case 32: /* Space */
					event.preventDefault();
					this.selectItem(this.selectedIndex);
					break;
				case 27: /* Escape */
				case 13: /* Enter */
					this.toggleSelect();
					break;
				default:
					break;
			}
		},
		scroll(direction) {
			if (direction === 'up') {
				this.$refs.list.scrollTop -= 33;
			} else {
				this.$refs.list.scrollTop += 33;
			}
			this.scrollPos = this.$refs.list.offsetTop;
		},
		emitOptions() {
			if (this.options.length !== this.numSelectedItems && this.numSelectedItems !== 0) {
				this.$emit('optionSelected', {id: this.id, options: this.options, hasToggleAll: this.hasToggleAll});
			}
		},
		setQueryParams() {
			let selectedOptions = [];
			let url = window.location.toString();

			this.options.filter(option => {
				if (option.checked) {
					selectedOptions.push(option.value);
				}
			});

			let newUrl = this.replaceUrlParam(url, this.id, selectedOptions.join('%2C'));

			newUrl = new URL(newUrl);
			window.history.pushState('', '', newUrl.search);
		},
		replaceUrlParam(url, name, value) {
			if (value == null) {
				value = '';
			}
			let pattern = new RegExp('\\b('+name+'=).*?(&|#|$)');
			if (url.search(pattern)>=0) {
				return url.replace(pattern,'$1' + value + '$2');
			}
			url = url.replace(/[?#]$/,'');
			return url + (url.indexOf('?')>0 ? '&' : '?') + name + '=' + value;
		},
		getQueryParamsFromURL (name) {
			name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
			let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
			let results = regex.exec(location.search);
			return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
		},
		setFilterFromParams (values) {
			values.forEach((value) => {
				this.options.forEach((option) => {
					if (option.value === value) {
						option.checked = true;
					} else if (!values.includes(option.value)) {
						option.checked = false;
					}
				});
			});

			if (this.options.length !== this.numSelectedItems && this.numSelectedItems !== 0) {
				this.$emit('optionSelected', {id: this.id, options: this.options, hasToggleAll: this.hasToggleAll});
			}
		}
	},
	created() {
		let queryParams = this.getQueryParamsFromURL(this.id);
		queryParams = decodeURI(queryParams);

		if (queryParams) {
			let values = queryParams.split(",");
			this.setFilterFromParams(values);
		}
	}
}
</script>
